<!--  -->
<template>
  <div class="mapDetailsW">
          <div class="mapDetailsW_title" @click="onClick()">{{title}}</div>
          <div class="mapDetailsW_map">
             <el-amap vid="amapDemo" :zoom="zoom" :center="center">
                <el-amap-marker 
                   v-for="(item,index) in dataArr"
                  :key="index"
                  :position="[item.longitude,item.latitude]"
                  :vid="index"
                >
                 <div class="mapDetailsW_rect" >
                    <p>{{item.name}}</p>
                    <i class="mapDetailsW_rect_triangle"></i>
                    <div class="mapDetailsW_rect_circle"></div>
                  </div>
                </el-amap-marker>

                <el-amap-marker 
                  :position="[deArr.building.longitude,deArr.building.latitude]"
                  :vid="1"
                >
                 <div class="mapDetailsW_rect1 redCont" >
                    <p>{{deArr.project.projectName}}</p>
                    <i></i>
                  </div>
                </el-amap-marker>

             </el-amap>
             <div class="tagCont">
                 <div class="menuBtn">
                     <span ref="btn1" @click="changeMap(1)">公共资源</span> <span ref="btn2" @click="changeMap(2)">交通资源</span> 
                 </div>
                 <ul v-if="dataArr.length!=0">
                   <li  v-for="(item,index) in dataArr" :key="index" @click="tagClick(item)">
                      <div><img class="runIcon" :src="item.img"> {{item.name}}</div> 
                      <!-- <div><img class="runIcon" src="@/assets/images/resource1.png"  alt=""> {{item.name}}</div> -->
                      
                       <div><i class="el-icon-position"></i>{{item.distance | dis}}米</div>
                   </li>
                 </ul>
                 <div v-else class="tip">暂无信息</div>
             </div>
             
          </div>
          

  </div>
</template>

<script>
import { number } from 'echarts';
import {getHouseNearBy} from '@/api/index'
import GaodeMap from 'vue-amap';

export default {
name: '',
  data () {
    return {
         zoom:17,
　　      zooms:[10,20],
// 　　      center:[104.10194,30.65984],
　　      center:[102.719148,24.974782],
         
         deArr:{building:{longitude:'12',latitude:'212',buildingName:'test'},project:{projectName:'dd'}},

          preId:1,
          currentId:1,
          publicResourceArr:[],
          publicTransitArr:[],
          dataArr:[],

          label:{
          content: '自定义标题',
          offset:[-30,30]
        }




    };
  },

  components: {},
  filters: {
    dis: function(value) {
      value = Math.floor(value*1000)
      return value; // 首字母大写
    }
  },


  props: {
        title:{
        type: String,
        default: '',
        required: true
      },
        id:{
        type: String,
        default: '',
        required: true
      },
       data:{
        type: Object,
        default:()=>{},
        required: true
      },


      
  },

  computed: {},

  watch: {
     data(newValue,oldValue){
       if(newValue){
        
         this.center=[this.data.building.longitude,this.data.building.latitude]
         this.deArr=newValue

         setTimeout(()=>{
           this.center=[this.deArr.building.longitude,this.deArr.building.latitude]
           this.zoom=17
         },300)
         


        //  console.log(this.center,'this.center')

        //  alert(JSON.stringify(this.deArr))

       }
     }
  },

  methods: {
    onClick(){
            

    },
    tagClick(_obj){
      this.center=[_obj.longitude,_obj.latitude]
      this.zoom=130

    },
    changeMap(_id){
        // console.log(_id,'_idididiid')
         if(_id==this.preId)
            return

            if(_id==1)
             this.dataArr=this.publicResourceArr
            if(_id==2)
             this.dataArr=this.publicTransitArr

            // console.log(this.dataArr,'_idididiid')
            this.currentId=_id
            this.$refs["btn"+this.currentId].classList.add('spanA')

            if(this.preId!=0)
            this.$refs["btn"+this.preId].classList.remove('spanA')
            this.preId=_id

            

           
            // this.center=[this.dataArr[0].longitude,this.dataArr[0].latitude]
            //  if(this.dataArr.length!=0)
            //  this.center=[this.data.building.longitude,this.data.building.latitude]


          //  console.log(this.zoom,'zoom1111')

           
              setTimeout(()=>{
               if(this.dataArr.length!=0)
                this.center=[this.deArr.building.longitude,this.deArr.building.latitude]
                this.zoom=17
              },300)
            //  console.log(this.zoom,'zoom3333')


            
          //  console.log(this.zoom,'zoom2222')
          //  console.log(this.center,'this.center111')



    }
  },

  created(){},//生命周期 - 创建完成（可以访问当前this实例）
  mounted(){
      // alert('3333')
      this.$refs["btn"+this.currentId].classList.add('spanA')
      // console.log(this.id,'idididididi')
       getHouseNearBy({id:this.id})
                .then((data) => {

                   if(data.code==200){
                        // console.log(data,'getHouseNearBy')
                        // this.newTotal=data.data.total
                        // publicResourceArr:[],
                        // publicTransitArr
                        let res=['facilityHospital','facilityPark','facilityHealth','facilityHotel','facilitySchool']
                        let tran=['facilityStation','facilityBusstop']
                        for(let key  in data.data){
                          //  console.log(key)
                           tran.forEach((value,index)=>{
                              if(value==key){
                                 if(key=='facilityStation'){
                                    data.data[value].map(function(item,index) {
                                        item.img=require('@/assets/images/transit1.png')
                                    })
                                }
                                if(key=='facilityBusstop'){
                                    data.data[value].map(function(item,index) {
                                        item.img=require('@/assets/images/transit2.png')
                                    })
                                }
                                 
                                 data.data[value].ethan='ddd'
                                //  console.log(data.data[value],'objobj')
                                 this.publicTransitArr=this.publicTransitArr.concat(data.data[value])
                              }
                               

                           })
                            res.forEach((value,index)=>{
                              if(value==key){
                                if(key=='facilityHospital'){
                                    data.data[value].map(function(item,index) {
                                        item.img=require('@/assets/images/resource5.png')
                                    })
                                }
                                if(key=='facilityPark'){
                                    data.data[value].map(function(item,index) {
                                        item.img=require('@/assets/images/resource1.png')
                                    })
                                }
                                if(key=='facilityHealth'){
                                    data.data[value].map(function(item,index) {
                                        item.img=require('@/assets/images/resource3.png')
                                    })
                                }
                                if(key=='facilityHotel'){
                                    data.data[value].map(function(item,index) {
                                        item.img=require('@/assets/images/resource2.png')
                                    })
                                }
                                if(key=='facilitySchool'){
                                    data.data[value].map(function(item,index) {
                                        item.img=require('@/assets/images/resource4.png')
                                    })
                                }
                                
                                // console.log(data.data[value],'obj1obj1')
                                this.publicResourceArr=this.publicResourceArr.concat(data.data[value])

                              }
                               

                           })
                           
                        }
                      //  this.deArr=this.data
                      // alert(JSON.stringify(this.data))


                        // console.log(this.publicTransitArr,'this.publicTransitArr')
                        // console.log(this.publicResourceArr,'this.publicResourceArr')
                         this.dataArr=this.publicResourceArr
                         if(this.dataArr.length!=0)
                         this.center=[this.dataArr[0].longitude,this.dataArr[0].latitude]




                    

                   }else{
                       this.$message.error(data.message);
                   } 
               

                // this.playlist = data.playlist
                })
                .catch((err) => {
                    console.log(err)
                    this.$message.error(err);
                })  

          //  this.$nextTick(()=>{
          //       setTimeout(()=>{
          //         GaodeMap.initAMapApiLoader({
          //       // 高德的key
          //       key: '5bb582d2c5a0a192ab5ca8a39c9967c3',
          //       // 插件集合
          //       plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor'],
          //       // 高德 sdk 版本，默认为 1.4.4
          //       v: '1.4.15'
          //        });
          //       },2000)
          //     });

  },//生命周期 - 挂载完成（可以访问DOM元素
  beforeCreate(){ },//生命周期 - 创建之前
  beforeMount(){ } ,//生命周期 - 挂载之前
  beforeUpdate(){ },//生命周期 - 更新之前
  updated(){ },//生命周期 - 更新之后
  beforeDestroy(){ },//生命周期 - 销毁之前
  destroyed(){ },//生命周期 - 销毁完成
  activated(){ }//如果页面有keep-alive缓存功能，这个函数会触发
}

</script>
<style lang='scss'>
.amap-marker:not(.redCont){
  z-index: 99 !important;
}
.mapDetailsW{
    [class*=" el-icon-"], [class^=el-icon-] {
    color: rgb(44, 44, 44)(58, 58, 58) !important;
    font-size: 20px;
      }

      .amap-marker-label{
        background:#296ABC;
        // background: #80FFA5;
        color: white;
        font-size: 12px;
        font-weight: bold;
        padding: 10px;
        border: none;

      }
    }
</style>

<style lang='scss' scoped>
@import "@/assets/css/mixin";

 .mapDetailsW{
    // padding: 20px 0 39px 0;
    width: $page-width;
    margin:auto;

     &_rect1{
    
    
     padding: 13px 13px;
     color: white;
     font-size: 14px;
     font-weight: bold;
    //  width: 100px;
    //  border-radius: 11px;
     white-space: nowrap;
     text-align: center;
    //  box-shadow: 1px 1px 8px #3b8df1;
      p{
       text-align: center;
       color: #F26564;
       text-shadow:1px 1px 3px rgb(194, 194, 194);
      // text-shadow: .2rem 0rem .5rem #cacaca,-.2rem 0rem .5rem cacaca,0rem .2rem .5rem cacaca,0rem -.2rem .5rem cacaca;

       
      // text-shadow: 13px 0rem 13px rgb(190, 190, 190);
      //  margin-top:15px;

      }
      i{
         width: 60px;
        height: 60px;
        background: url('../../assets/images/mainLoc.png') no-repeat center ; 
        background-size: contain;
         position: absolute;
        bottom: -45px;
        margin: auto;
        left: 0;
        right: 0;
        
      }
     }
    

     &_rect{
     background: #3b8df1;
     padding: 13px 13px;
     color: white;
     font-size: 14px;
     font-weight: bold;
    //  width: 100px;
    //  border-radius: 11px;
     white-space: nowrap;
     text-align: center;
     box-shadow: 1px 1px 8px #3b8df1;

     &:hover{
      //  background: #1e518f;
      //  .mapDetailsW_rect_triangle{
      //   border-top: 10px solid #1e518f;

      //  }

     }
    //  box-sizing: border-box;
     p{
       text-align: center;

     }
     &_triangle{
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid #3b8df1;
        position: absolute;
        bottom: -6px;
         margin: auto;
        left: 0;
        right: 0;
       

     }
     &_circle{
        width: 9px;
        height: 9px;
        background: #3b8df1;
        border: 2px solid white;
        position: absolute;
        bottom: -20px;
        margin: auto;
        left: 0;
        right: 0;
        border-radius: 100%;
         box-shadow: 1px 1px 8px #3b8df1;


     }
     &_name{
       display: flex;
       justify-content: space-between;
       align-items: center;
       span:nth-child(2){
          font-weight: bold;
          margin-left: 30px;
       }

     }}
    .runIcon{
      width: 16px;
      height: 16px;
    }
     &_title{
            font-size: 20px;
            font-weight: bold;
            color: #296ABC;
            border-bottom: 1px solid #10406B;
            padding-bottom: 10px;
        }
    &_map{
        width: 96%;
        height: 500px;
        margin:48px auto;
        background: seagreen;
        position: relative;
        .tagCont{
          width: 360px;
          height: 466px;
          background: white;
          position: absolute;
          top: 3%;
          right: 2%;
          .tip{
              color: #7c7c7c;
              font-size: 14px;
              text-align: center;
              padding: 60px 0;

          }
           ul{
             overflow-y: scroll;
             height: 410px;
             li{ 
                cursor: pointer;
                display: flex;
                justify-content: space-between;
                font-size: 14px;
                padding: 0 20px;
                margin: 30px 0;
              
                // font-weight: bold;
                color: #272727;
                div{
                   @include no-wrap;
                    @include clamp(1);
                    width: 280px;
                     justify-content: flex-start;
                     display: flex;
                    align-items: center;
                    img,i{
                      margin-right: 6px;
                      
                    }

                }
                 >div:nth-child(1){
                  // background: seagreen;
                  font-weight: bold;
                }
                >div:nth-child(2){
                  // background: seagreen;
                  width: 100px;
                }
             }

          }
          .menuBtn{
            width: 100%;
            height: 40px;
            display: flex;
            
            span{
              background: #e9e9e9;
              width: 50%;
              height: 100%;
              text-align: center;
              display: block;
              color: #636363;
              font-size: 16px;
              font-weight: bold;
              line-height: 40px;
              border-top: 3px solid #e9e9e9;
              cursor: pointer;
            }
            // span:hover{
            //    color: #296ABC;
            //    background: #ffffff;
            //    border-top: 3px solid #296ABC;
            // }
            .spanA{
               color: #296ABC;
               background: #ffffff;
               border-top: 3px solid #296ABC;
            }
          }
        }

    }
 }
</style>