<!--  -->
<template>
  <div class="cardDetailsW">
          <div class="cardDetailsW_title">{{title}} 
              <div class="cardDetailsW_title_btn" @click="seePic" v-show="type=='2'">
                   <img src="@/assets/images/areaIcon.jpg" alt="">
                   查看面积图例
              </div>
          </div>

          <div class="typeCont" v-show="type=='1'">
            <div class="cardDetailsW_subT" v-show="tagList.length!=0">
                    <div v-for="(item,index) in tagList" :key="index" :class="'cardDetailsW_subT_tag'+(index+2)">{{tagList[index]}}</div>
                    <!-- <div class="cardDetailsW_subT_tag2">千万元楼宇</div>
                    <div class="cardDetailsW_subT_tag3">自贸区楼宇</div> -->
            </div>

            <div class="cardDetailsW_info">
                <ul class="leftC">
                    <li class="leftC_sub" v-for="(item,index) in leftList" :key="index">
                            <div class="leftC_sub_title">{{item.title}}</div>
                            <div class="leftC_sub_content">{{item.content}}</div>
                    </li>
                </ul>

                <ul class="rightC">
                    <li class="rightC_sub" v-for="(item,index) in rightList" :key="index">
                            <div class="rightC_sub_title">{{item.title}}</div>
                            <div class="rightC_sub_content">{{item.content}}</div>
                    </li>
                </ul>
            </div>
           </div>


           <div class="typeCont floorCont" v-show="type=='2'">
               <div class="cardDetailsW_cont">
                   <div class="titleCont">
                       <div class="rentTitle">出租</div>
                       <div class="sellTitle">出售</div>
                       
                   </div>
                   <ul class="menu">
                       <li class="menu_sub" v-for="(item,index) in menuArr" :key="index"  v-html="item"></li>
                   </ul>

                   <ul class="content">
                       <li class="content_sub" v-for="(item,index) in contentArr" :key="index">
                           <span v-for="(item1,index1) in item" :key="index1">{{item1!=null?item1:(index1!=1 && index1!=4)?0:''}}</span>
                       </li>
                   </ul>
               </div>

           </div>


           <div class="typeCont" v-show="type=='3'">
               <div class="cardDetailsW_cont1">
                   <ul class="menu">
                       <li class="menu_sub" v-for="(item,index) in menuArr1" :key="index"  v-html="item"></li>
                   </ul>

                   <ul class="content1">
                       <li class="content1_sub" v-for="(item,index) in contentArr" :key="index">
                           <span v-for="(item1,index1) in item" :key="index1">{{item1}}</span>
                       </li>
                   </ul>
               </div>

           </div>


           

  </div>
</template>

<script>
export default {
name: '',
  data () {
    return {
        menuArr:['楼层','本层<br>租售方式','本层<br>建筑面积㎡','装修标准','本层<br>招商用途','楼层<br>空置率','本层可租<br>面积㎡','本层最大连续<br>可租面积㎡','本层最小可租<br>单间面积㎡','本层可售<br>总面积㎡','本层最大连续<br>可售面积㎡','本层最小可售<br>单间面积㎡'],
        menuArr1:['商户名称','统一社会信用代码','行业类型','所处楼层','是否IP注册'],

    };
  },

  components: {},

  props: {
      contentArr:[],
      leftList:{
           type:Array,
           default:()=>[],
            required: true
      },
      rightList:{
           type:Array,
           default:()=>[],
            required: true
      },
      tagList:{
            type: Array,
            default:()=>[],
            required: true
      },
       title:{
        type: String,
        default: '',
        required: true
      },
       type:{
        type: String,
        default: '',
        required: true
      },
  },

  computed: {},

  watch: {},

  methods: {
      seePic(){
        // this.$alert('<strong>这是 <i>HTML</i> 片段</strong>', 'HTML 片段', {
        //   dangerouslyUseHTMLString: true
        // });
        this.$emit('viewAreaPic')
      
      }
  },

  created(){},//生命周期 - 创建完成（可以访问当前this实例）
  mounted(){},//生命周期 - 挂载完成（可以访问DOM元素
  beforeCreate(){ },//生命周期 - 创建之前
  beforeMount(){ } ,//生命周期 - 挂载之前
  beforeUpdate(){ },//生命周期 - 更新之前
  updated(){ },//生命周期 - 更新之后
  beforeDestroy(){ },//生命周期 - 销毁之前
  destroyed(){ },//生命周期 - 销毁完成
  activated(){ }//如果页面有keep-alive缓存功能，这个函数会触发
}

</script>
<style lang='scss' scoped>
@import "@/assets/css/mixin";
.floorCont{
    overflow: scroll;
    .menu{
        
       li{
            font-size: 16px;
       }
       li:nth-child(1){
           margin-top: $top;
           font-size: $font_large;
       }
       li:nth-child(2){
           margin-top: $top;
           font-size: $font_large;
       }
         li:nth-child(3){
           margin-top: $top;
           font-size: $font_large;
       }
       li:nth-child(4){
           margin-top: $top;
           font-size: $font_large;
       }
       li:nth-child(5){
           margin-top: $top;
           font-size: $font_large;
       }
       li:nth-child(6){
           margin-top: $top;
           font-size: $font_large;
       }
   }
}
.titleCont{
    position: relative;
    width: 100%;
    height: 40px;
    .rentTitle{
    font-size: 20px;
    font-weight: 400;
    color: #333333;
    width: 366px;
    border-bottom: 1px solid #333333;
    text-align: center;
    position: absolute;
    top: -10px;
    left: 788px;
    padding-bottom: 6px;
   }

   .sellTitle{
    font-size: 20px;
    font-weight: 400;
    color: #333333;
    width: 366px;
    border-bottom: 1px solid #333333;
    text-align: center;
    position: absolute;
    top: -10px;
    left: 1203px;
    padding-bottom: 6px;
   }

   


}

 .cardDetailsW{
    // padding: 20px 0 39px 0;
    width: $page-width;
    margin:auto;
   
    &_cont,&_cont1{
        background: #F7F8FA;
        padding: 24px;
        height: 666px;
        .menu{
              display: flex;
             align-items: center;
             justify-content: space-between;
             border-bottom: 1px solid rgba(16, 64, 107,0.19);
             padding: 1px 30px 18px 30px;
             box-sizing: border-box;

             &_sub{
                font-size: 18px;
                font-weight: 400;
                color: #333333;
                text-align: center;

             }
        }
        .content{
            //  padding: 1px 73px 18px 33px;
                box-sizing: border-box;
                margin-top: 20px;
                height: 560px;
               
                overflow: scroll;
                // background: seagreen;

             &_sub{
                display: flex;
                align-items: center;
                justify-content: flex-start; 
                font-size: 18px;
                font-weight: 400;
                color: #333333;
                text-align: center;
                margin: 20px 0;
                width: auto;
                span:nth-child(1){
                        width: 60px;
                        margin-left: 20px;
                    // background: seagreen;
                    
                }
                span:nth-child(2){
                    width: 130px;
                    // background: seagreen;
                    margin-left: 20px;
                }
                span:nth-child(3){
                    width: 100px;
                    // background: seagreen;
                    margin-left: 24px;
                }
                span:nth-child(4){
                    width: 100px;
                    // background: seagreen;
                    margin-left: 44px;
                }
                span:nth-child(5){
                    width: 110px;
                    // background: seagreen;
                    margin-left: 33px;
                }
                 span:nth-child(6){
                    width: 80px;
                    // background: seagreen;
                    margin-left: 28px;
                }
                 span:nth-child(7){
                    width: 80px;
                    // background: seagreen;
                    margin-left: 33px;
                }
                 span:nth-child(8){
                    width: 80px;
                    // background: seagreen;
                    margin-left: 53px;
                }
                 span:nth-child(9){
                    width: 80px;
                    // background: seagreen;
                    margin-left: 73px;
                }
                 span:nth-child(10){
                    width: 80px;
                    // background: seagreen;
                    margin-left: 53px;
                }
                 span:nth-child(11){
                    width: 80px;
                    // background: seagreen;
                    margin-left: 56px;
                }
                 span:nth-child(12){
                    width: 80px;
                    // background: seagreen;
                    margin-left: 66px;
                }
                 
             }
        }


        .content1{
            //  padding: 1px 73px 18px 33px;
                box-sizing: border-box;
                margin-top: 20px;
                height: 280px;
                overflow: scroll;
                // background: seagreen;
         

             &_sub{
                display: flex;
                align-items: center;
                justify-content: flex-start; 
                font-size: 18px;
                font-weight: 400;
                color: #333333;
                text-align: center;
                margin: 20px 0;
                span:nth-child(1){
                        width: 340px;
                        margin-left: 0px;
                    // background: seagreen;
                    
                }
                span:nth-child(2){
                       width: 230px;
                       margin-left: 64px;
                }
                span:nth-child(3){
                    width: 166px;
                    // background: seagreen;
                    margin-left: 16px;
                }
                span:nth-child(4){
                    width: 88px;
                    // background: seagreen;
                    margin-left: 40px;
                }
                 span:nth-child(5){
                    width: 68px;
                    // background: seagreen;
                    margin-left: 100px;
                }
        
             }
        }

    }
     &_cont{
         width: 1600px;
    }
     &_cont1{
        height: 335px;
            .menu{
                   li:nth-child(1){
                        margin-left: 100px;
                    // background: seagreen;
                    
                 }
                    li:nth-child(2){
                        margin-left: 150px;
                    }

            }

    }
    // margin-top: 15px;
        &_title{
            font-size: 20px;
            font-weight: bold;
            color: #296ABC;
            border-bottom: 1px solid #10406B;
            padding-bottom: 10px;
            position: relative;
             &_btn{
               cursor: pointer;
               position: absolute;
               top: 0px;
               left: 166px;
               background: #296ABC;
               border-radius: 13px;
               display: flex;
               align-items: center;
               justify-content: center;

                font-size: 14px;
                font-weight: 400;
                color: #FFFFFF;
                padding: 5px 12px;
                box-sizing: border-box;
               img{
                   width: 12px;
                   height: 11px;
                   margin-right: 6px;
               }

            }
        }
        &_subT{
             display: flex;
             align-items: center;
             justify-content: flex-start;
             margin-top: 20px;
             &_tag1{
                 background: #79AAD5;
                 border-radius: 13px;
                 font-size: 14px;
                 padding: 3px 12px;
                 margin-right: 8px;
             }
              &_tag2{
                 background: #79AAD5;
                 border-radius: 13px;
                 font-size: 14px;
                 padding: 3px 12px;
                 margin-right: 8px;
             }
              &_tag3{
                 background: #339B00;
                 border-radius: 13px;
                 font-size: 14px;
                 padding: 3px 12px;
             }
        }

        &_info{
             display: flex;
             justify-content: space-between;
             margin: 28px 43px 0 60px;
            .leftC{
                &_sub{
                    width: 416px;
                    margin-bottom: 10px;
                    display: flex;
                    justify-content: space-between;
                     &_title{
                        font-size: 18px;
                        font-weight: 400;
                        color: #999999;
                     }
                      &_content{
                        font-size: 18px;
                        font-weight: 400;
                        color: #333333;
                        width: 300px;
                         text-align: right;
                     }
                }

            }

             .rightC{
                &_sub{
                    width: 416px;
                    margin-bottom: 10px;
                    display: flex;
                    justify-content: space-between;
                     &_title{
                        font-size: 18px;
                        font-weight: 400;
                        color: #999999;
                     }
                      &_content{
                        font-size: 18px;
                        font-weight: 400;
                        color: #333333;
                         width: 300px;
                         text-align: right;
                     }
                }

            }


        }

        
    }
</style>