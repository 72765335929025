<!--  -->
<template>
  <div class="roomQueryW">

    <div class="roomQueryCont">
      <div class="roomQuery">
        <div class="roomQuery_title">请选择筛选条件</div>
            <CardQuqry class="roomQuery_sub" v-for="(item,index) in data" :key="index" :apiName="item.apiName" :type="item.type"  :title="item.title" :tagList="item.obj" :isVisible="item.isVisible"  @str="tagStr"></CardQuqry>
      </div>
    </div>

      <div class="roomQueryMiddle">
        <ul class="roomQuerylist">
          <li  class="roomQuerylist_sub" v-for="(item,index) in searchTypeArr" :key="index" :ref="`btn${index+1}`" @click="changePage((index+1),true)">
              {{item}}
          </li>
        </ul>
        <div class="roomQueryMiddle_txt">共找到  <span>{{newTotal}}</span>  栋符合条件的楼宇</div>

        <div class="roomQueryMiddle_body"  v-if="newTotal>0">
            <CardSub class="roomQueryMiddle_body_sub" v-for="(item,index) in recommendList" :type="0" :infoList="item" :key="index"></CardSub>
        </div>

        <div v-else class="noData">暂无相关内容!</div>

        

        
        

      <div class="roomQueryMiddle_pageination"  v-if="newTotal>7">
         <el-pagination
          background
          layout="prev, pager, next"
          :total="newTotal"
          :page-size="newPageSize"
          :current-page="newCurrentPage"
          @current-change="currentChange">
        </el-pagination>
      </div>
       

      </div>


      <div class="topBtn" @click="backTop"></div>


  </div>

</template>

<script>
import CardQuqry from '@/components/Quqry/CardQuqry.vue'
import CardSub from '@/components/Home/CardSub.vue'
import {getNewsList,getMock,getNewsPagingList,getHousePagingList,getTownsList,getDictList} from '@/api/index'
import {mapActions,mapGetters} from 'vuex' 
import mode from '@/api/modeType'

export default {
name: '',
  data () {
    return {
      pathUrl:'',
      newTotal:0,
      newPageSize:6,
      newCurrentPage:1,
      paginationIsVisible:true,

      isVisible:false,
      apiObj:{
        orderCol:null
       
      },
      
      preId:-1,
      recommendList:[],
      searchTypeArr:[
        '默认排序','租金水平','空置面积','建成年份'
      ],
    
     
      data:[
        {
          title:'楼宇类型:',
          obj:['纯商务','商业综合体'],
          type:0,
          apiName:'projectCategory',
          isVisible:true
        },
        {
          title:'最高租金:',
          obj:[{value:'30以下',name:'30以下'},{value:'30-60',name:'30-60'},{value:'60-90',name:'60-90'}],
          type:1,
          apiName:'rentMax',
          isVisible:true
        },
        {
          title:'单层连续可租面积㎡:',
          obj:[],
          type:2,
          apiName:'rentArea',
          isVisible:true
        },
         {
          title:'空置率:',
          obj:[],
          type:3,
          apiName:'vacancyRate',
          isVisible:this.isVisible
        },
        
        {
          title:'建成年份:',
          obj:[{value:'5年内',name:'5年内'},{value:'5-10年',name:'5-10年'},{value:'10-15年',name:'10-15年'},{value:'15年以上',name:'15年以上'}],
          type:0,
          apiName:'setupYearMax',
          isVisible:true
        },
        {
          title:'所属街道:',
          obj:['关上','太和','吴井','金马','小板桥','矣六','六甲','官渡'],
          type:0,
          apiName:'townId',
          isVisible:true
        },
        {
          title:'楼宇特色:',
          obj:['千万元楼宇','亿元楼宇','其他楼宇','自贸区楼宇'],
          type:0,
          apiName:'identifiedLevel',
          apiName1:'ifFreeTrade',
          isVisible:true
        },
        {
          title:'装修标准:',
          obj:['毛坯','普通装修','精装修'],
          type:0,
          apiName:'decorateStandard',
          isVisible:true
        },
        {
          title:'运营模式:',
          obj:['全部自持','部分自持','非自持'],
          type:0,
          apiName:'operatingMethod',
          isVisible:true
         },
         
      ],

    };
  },

  components: {
    CardQuqry,
    CardSub
  },

  props: {},

  computed: {
      ...mapGetters([
          'getPageID',
          'getIsLogin',
          'getKeyword',
          'getTopBoo'
      ]),
  },

  watch: {
        getTopBoo(newValue,oldValue){
          console.log(newValue,'getTopBoo')
          if(newValue){
            this.setTopBoo(false)
            // document.documentElement.scrollTop = document.body.scrollTop = 1400
           this.scroll(1400)
          }
           

        },
       getKeyword(newValue,oldValue){
          // if(newValue){
            // console.log(newValue,'getKeyword')
            
            if(this.getKeyword==''){
               delete this.apiObj['search']
            }else{
               this.apiObj['search']=this.getKeyword
            }
            this.newCurrentPage=1
            this.apiObj['pageNo']=1
            this.setHousePagingList(this.apiObj)
          // }

       }
  },
 
  methods: {
      ...mapActions([
          'setPageID',
          'setKeyword',
          'setTopBoo'
      ]),
    backTop(){
       document.documentElement.scrollTop = document.body.scrollTop = 0
    }, 

    scroll(_x){
            let location = 0;
            let time = setInterval(function () {
                location += 60;
                document.documentElement.scrollTop = document.body.scrollTop = location       // 按钮返回函数
                if (location >_x) {
                    clearInterval(time);          //结束滚轴跳转后，滚轴位置归零
                    document.documentElement.scrollTop = document.body.scrollTop = _x
                }
            // 添加滚轴事件，判定在返回过程中，滚轴y位置发生变化，即终止定时器
            // window.addEventListener("scroll", function () {
            //         if (scrollY != _x) {
            //             clearInterval(time);
            //         }
            //     })
            }, 10);

    },

    tagStr(_title,_str,_boo){
        //  console.log(_title,'_title')
        //  console.log(_str,'_str')

         if(_title=='identifiedLevel')
         delete this.apiObj['ifFreeTrade']

        //   if(_title=='ifFreeTrade')
        //  delete this.apiObj['identifiedLevel']

          if(_str==-1){
            delete this.apiObj[_title]
            if(_title=='rentMax')
            delete this.apiObj['rentMin']

            if(_title=='setupYearMax')
            delete this.apiObj['setupYearMin']

            
              
           }else{
           
            if(_title=='setupYearMax'){
               if(_str=="5年内"){
                 this.apiObj['setupYearMin']=0
                 this.apiObj['setupYearMax']=5
               }
               if(_str=="5-10年"){
                 this.apiObj['setupYearMin']=5
                 this.apiObj['setupYearMax']=10
               }
               if(_str=="10-15年"){
                 this.apiObj['setupYearMin']=10
                 this.apiObj['setupYearMax']=15
               }
               if(_str=="15年以上"){
                 this.apiObj['setupYearMin']=15
                 delete this.apiObj['setupYearMax']
               }
                

              }else if(_str=='自贸区楼宇'){
                this.apiObj['ifFreeTrade']='是'
                delete this.apiObj['identifiedLevel']

              }else if(_title=='rentMax'){
                //  console.log(typeof(_str),'typeof(_str)')
                if(typeof(_str)!="string"){
                        this.apiObj['rentMin']=_str[0]
                        this.apiObj['rentMax']=_str[1]
                }else{
                     if(_str=="30以下"){
                        this.apiObj['rentMin']=0
                        this.apiObj['rentMax']=30
                      }
                      if(_str=="30-60"){
                         this.apiObj['rentMin']=30
                        this.apiObj['rentMax']=60
                      }
                      if(_str=="60-90"){
                         this.apiObj['rentMin']=60
                        this.apiObj['rentMax']=90
                      }
                      

                }
              

              }else{
                if(_str!='自贸区楼宇')
                  this.apiObj[_title]=_str
              }
           }
        
        // this.newCurrentPage=1
        this.newCurrentPage=mode.newCurrentPage

        console.log(mode.newCurrentPage,'mode.newCurrentPage1111')
        this.apiObj['pageNo']=this.newCurrentPage

       

        // console.log(this.apiObj,'apiObjapiObj')
        mode.apiObj=this.apiObj


        if(_boo)
        this.setHousePagingList(this.apiObj)


    },
     currentChange(val){
          // console.log(`当前页: ${val}`);
          console.log(this.newCurrentPage,'newCurrentPage')
          this.newCurrentPage=val
          mode.newCurrentPage=this.newCurrentPage
          this.apiObj['pageNo']=this.newCurrentPage
          // console.log(this.apiObj,'apiObjapiObj')
          

          this.setHousePagingList(this.apiObj)


      },

    setHousePagingList(obj){
      if(obj.vacancyRate){
        obj.vacancyRate=Number(obj.vacancyRate)
        // console.log(typeof(obj.rentArea),'obj.rentAreaobj.rentArea')
      }
      if(obj.rentArea){
        obj.rentArea=Number(obj.rentArea)
        // console.log(typeof(obj.rentArea),'obj.rentAreaobj.rentArea')
      }
      if(obj.rentMin){
        obj.rentMin=Number(obj.rentMin)
        // console.log(typeof(obj.rentMin),'obj.rentMin.rentMin')
      }
      if(obj.rentMax){
        obj.rentMax=Number(obj.rentMax)
        // console.log(typeof(obj.rentMax),'obj.rentMax.rentMax')
      }
      if(obj.setupYearMin){
        obj.setupYearMin=Number(obj.setupYearMin)
        // console.log(typeof(obj.setupYearMin),'obj.setupYearMin')
      }
       if(obj.setupYearMax){
        obj.setupYearMax=Number(obj.setupYearMax)
        // console.log(typeof(obj.setupYearMax),'obj.setupYearMax')
      }
         

       getHousePagingList(obj)
        .then((data) => {
          
          if(data.code==200){
            // console.log(data,'getHousePagingList')
            this.recommendList=data.data.records
            this.newTotal=data.data.total

            mode.roomObj=this.recommendList
            mode.newTotal=this.newTotal

              }else{
                  this.$message.error(data.message);
              }

          // this.playlist = data.playlist
        })
        .catch((err) => {
          console.log(err)
          this.$message.error(err);
        })  

    },  
    changePage(_id,_boo){
          // console.log(this.preId)

         if(_id==this.preId)
           return

        console.log(_id,'_id')

      if(_boo){
        this.newCurrentPage=1
        mode.newCurrentPage=this.newCurrentPage
      }
        

        if(_id==1)
        this.apiObj['orderCol']=null
        else
        this.apiObj['orderCol']=_id-1
     
        if(_boo)
        this.setHousePagingList(this.apiObj)

        mode.oderIndex=_id
  
         this.$refs[`btn${_id}`][0].classList.add('active')
         if(this.preId!=-1)
             this.$refs[`btn${this.preId}`][0].classList.remove('active')
         this.preId=_id

      }
  },

  created(){},//生命周期 - 创建完成（可以访问当前this实例）
  mounted(){
     console.log(this.pathUrl,'fromPath')
   
     this.setPageID(2)
    //  console.log(this.$refs.btn1,'mountedmountedmounted')
    //  console.log(this.preId)
    //  console.log(this.getKeyword,'this.getKeyword')
    //  console.log(this.getPageID,'this.getPageID')
      console.log(this.getTopBoo,'this.getTopBoo')
    if(this.getTopBoo || this.pathUrl=='RoomDetails'){
        mode.topBoo=false
        this.setTopBoo(false)
        // document.documentElement.scrollTop = document.body.scrollTop = 1400
        this.scroll(1400)
    }
    // alert(this.getKeyword=='')

    if(this.pathUrl=='Home' && this.getKeyword!=''){
      // alert('333')
      mode.newCurrentPage=1
      this.newCurrentPage=1
    }
     
           

      // this.newCurrentPage=mode.newCurrentPage
      this.apiObj['pageNo']=this.newCurrentPage
      this.apiObj['pageSize']=this.newPageSize
      if(this.getKeyword!='')
      this.apiObj['search']=this.getKeyword
      
     
    //  this.setHousePagingList(this.apiObj)

     this.changePage(mode.oderIndex,false)

    getDictList({keys:'resCreProject.projectCategory,resCreProject.identifiedLevel,resCreFloorOper.decorateStandard,resCreBuilding.operatingMethod'})
        .then((data) => {
          
          if(data.code==200){
            //  console.log(data.data["resCreProject.identifiedLevel"],'getDictList')
            //  for(var name in data){
            //      console.log(data[name],'getDictList111')
            //  }
              this.data[0].obj=data.data["resCreProject.projectCategory"]


              this.data[6].obj=data.data["resCreProject.identifiedLevel"]
              this.data[6].obj.push({name:'自贸区楼宇',value:'自贸区楼宇'})
              this.data[7].obj=data.data["resCreFloorOper.decorateStandard"]
              this.data[8].obj=data.data["resCreBuilding.operatingMethod"]

              // console.log(this.data,'this.datariririr')
              //  this.data[3].isVisible=this.getIsLogin
              //  mode.apiObj=this.apiObj
              //  this.setHousePagingList( mode.apiObj)
            
              }else{
                  this.$message.error(data.message);
              }

          // this.playlist = data.playlist
        })
        .catch((err) => {
          console.log(err)
          this.$message.error(err);
        })  

     
      getTownsList()
        .then((data) => {
          
          if(data.code==200){
            // console.log(data,'getTownsList')
              data.data.forEach(element => {
                // console.log(element,'element')
                element['value']= element['id']
                element['name']= element['areaName']

                
                delete element['id']
              });

              // console.log(data.data,'arrarrarr111')
              this.data[5].obj=data.data

              //  this.data[3].isVisible=this.getIsLogin
              //  mode.apiObj=this.apiObj
              //  this.setHousePagingList( mode.apiObj)
              // console.log(this.data,'datadata3333')

              }else{
                  this.$message.error(data.message);
              }

          // this.playlist = data.playlist
        })
        .catch((err) => {
          console.log(err)
          this.$message.error(err);
        })  
     
        //  this.data[8].isVisible=this.isVisible
         this.data[3].isVisible=this.getIsLogin

         setTimeout(()=>{
            console.log(this.apiObj,'this.apiObj')
            mode.apiObj=this.apiObj
            this.newCurrentPage=mode.newCurrentPage
            // alert(this.newCurrentPage)

            // this.setHousePagingList( mode.apiObj)

            if(mode.roomObj!=''){
             this.recommendList=mode.roomObj
            }else{
              this.setHousePagingList(this.apiObj)
            }
            
            if(mode.newTotal!=0)
            this.newTotal=mode.newTotal

            mode.jisBoo=true
         },100)
 
        //  setTimeout(()=>{
          
        //     //  this.newCurrentPage=mode.newCurrentPage
        //      console.log(this.newCurrentPage,'this.newCurrentPage')
          
        //  },4300)

     this.$refs[`btn${this.preId}`][0].classList.add('active')

     
      
      
  },//生命周期 - 挂载完成（可以访问DOM元素
  beforeRouteEnter(to, from, next) {
          next(vm=>{    
            
            console.log(to)
            console.log(from)
            vm.pathUrl = from.name;
          })
        },

  beforeCreate(){ },//生命周期 - 创建之前
  beforeMount(){ } ,//生命周期 - 挂载之前
  beforeUpdate(){ },//生命周期 - 更新之前
  updated(){ },//生命周期 - 更新之后
  beforeDestroy(){ },//生命周期 - 销毁之前
  destroyed(){ },//生命周期 - 销毁完成
  activated(){ }//如果页面有keep-alive缓存功能，这个函数会触发
}

</script>

<style lang='scss' >
.roomQueryMiddle_pageination{
[class*=" el-icon-"], [class^=el-icon-] {
    color: #9BA3A9;
}
.el-pagination.is-background .btn-next, .el-pagination.is-background .btn-prev, .el-pagination.is-background .el-pager li {
    margin: 0 12px;
    background-color: #E7EBEE;
    color: #9BA3A9;
    // min-width: 30px;
    border-radius: 5px;
}
.el-pagination button, .el-pagination span:not([class*=suffix]) {
    display: inline-block;
    font-size: 13px;
    width: 48px;
    height: 48px;
    line-height: 48px;
    vertical-align: top;
    box-sizing: border-box;
}
.el-pager li {
    padding: 0 4px;
    font-size: 18px;
    width: 48px;
    height: 48px;
    line-height: 48px;
    box-sizing: border-box;
    text-align: center;
    border-radius: 5px;
    background: red;
    font-weight:normal ;
}
.el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #296ABC;
    color: #FFF;
}
.el-pager .more::before{
  line-height: 48px;
}
}
</style>
<style lang='scss' scoped>
  @import "@/assets/css/variable";

.noData{
    text-align: center;
    padding: 110px 0;
    font-size: 20px;
    color: #8D8D8D;


}
.topBtn{
   width: 60px;
   height: 60px;
   background: url('../assets/images/top.png') no-repeat center ; 
   background-size: contain;
   position: fixed;
   bottom: 3%;
   right: 3%;
   cursor: pointer;
}
.roomQueryW{
  // width: 1200px;
  width: 100%;
  // height: 300px;
  // position: relative;
  overflow: hidden;
  margin: auto;
  position: relative;
  background: $background-color;
  z-index: 103;

.roomQueryCont{
  width: 100%;
  background: white;
  padding-top: 40px;
  padding-bottom: 35px;
}
.roomQuery{
  width: $page-width;
  margin: auto;
  &_title{
    font-size: 20px;
    color: #333333;
    border-bottom: 1px dashed #D5D6D6;
    padding-bottom: 15px;
    margin-bottom: 20px;
  }
   &_sub{
      // margin-top: 33px;
   }
}
   .roomQueryMiddle{
      width: $page-width;
      margin: auto;
      padding: 62px 0 86px 0;
      background: $background-color;
        &_pageination{
          display: flex;
          justify-content: center;
          margin-top: 60px;
        }
       &_body{

        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
         &_sub{
           margin: 0px;
           margin-bottom: 20px;
         }
      }


      &_txt{
       font-size:$font_medium ;
       color: #10406B;
       font-weight: bold;
       margin: 20px 0;
       span{
         color:#B50000;
       }
      }
     .roomQuerylist{
       font-size: 20px;
       color: #333333;
       width: 100%;
       display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 0 0 3px 0;
      border-bottom: 1px solid #10406B;
       &_sub{
         cursor: pointer;
         width: 168px;
         height: 45px;
         text-align: center;
         line-height: 45px;
         box-sizing: border-box;
        //  margin-right: 89px;
         border-radius: 18px 18px 0px 0px;
         background: none;
         &.active{
           background: #296ABC;
           color: white;
         }
       }
        &_sub:hover{
         background: #296ABC;
         color: white;
       }
     }

   }
}
</style>