<!--  -->
<template>
  <div class="swiperW">
     <img class="swiperW_img" :src="picBig" alt="">
     <div class="swiperGroup">
          <div class="swiperGroup_left swiper-button-next">
              <i class="el-icon-caret-left swiperGroup_left_icon"></i>
          </div>
          <ul>
         <swiper :options="swiperOption" class="swiperCont swiper-no-swiping" >
        <!-- slides -->
                <swiper-slide  class="side"  v-for="(vaule,key) in imageUrls" :key="key" >
                    <img :src="vaule" alt=""  @click.prevent="changePic(key)">
                </swiper-slide >        
                <!-- <div class="swiper-pagination"  slot="pagination"></div> -->
        </swiper>
              
          </ul>
          <div class="swiperGroup_right swiper-button-prev">
              <i class="el-icon-caret-right swiperGroup_right_icon"></i>
          </div>
     </div>

 
 


  </div>
</template>

<script>
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
export default {
name: '',
  data () {
    return {
         picBig:'',
        swiperOption: {
               
                // loop: false, // 循环模式选项
                slidesPerView: 4,
                spaceBetween: 3,
                preventClicks : false,
                // autoplay: {
                // delay: 1000, // 自动切换的时间间隔，单位ms
                // stopOnLastSlide: false, // 当切换到最后一个slide时停止自动切换
                // disableOnInteraction: false // 用户操作swiper之后，是否禁止autoplay。
                // },
                // 如果需要分页器
                // pagination: {
                //  el: '.swiper-pagination',
                //  bulletClass: 'my-bullet',
                //  bulletActiveClass: 'my-bullet-active'
                // },
                navigation: {
                    nextEl: ".swiper-button-prev",
                    prevEl: ".swiper-button-next",
                    },
                //  on:{
                //     slideChange(){
                //     console.log('改变了，activeIndex为'+this.activeIndex);
                //     // that.yh=this.activeIndex
                    
                //     },
                // },
                // activeIndex:0,
                // observer: true,
                // observeParents: true,
                // observeSlideChildren: true
      }



    };
  },

  components: {
      swiper,
      swiperSlide
  },

  props: {
    imageUrls:{
      type: Array,
      default:()=>[],
      required: true
    },
  },

  computed: {},

  watch: {},

  methods: {
        onSlideChangeStart (currentPage) {
            // console.log(currentPage);
        },
        changePic(_id){
            // console.log(_id)
            this.picBig=this.imageUrls[_id]
        }
  },

  created(){
      
  },//生命周期 - 创建完成（可以访问当前this实例）
  mounted(){
       this.$nextTick(()=>{
        setTimeout(()=>{
            this.picBig=this.imageUrls[0]
            // console.log(this.imageUrls,'this.picBig')
        },600)
        
      })
      
  },//生命周期 - 挂载完成（可以访问DOM元素
  beforeCreate(){ },//生命周期 - 创建之前
  beforeMount(){ } ,//生命周期 - 挂载之前
  beforeUpdate(){ },//生命周期 - 更新之前
  updated(){ },//生命周期 - 更新之后
  beforeDestroy(){ },//生命周期 - 销毁之前
  destroyed(){ },//生命周期 - 销毁完成
  activated(){ }//如果页面有keep-alive缓存功能，这个函数会触发
}

</script>
<style lang='scss'>
// [class*=" el-icon-"], [class^=el-icon-] {
//     color: white;
//     font-size: 20px;
// }
 .swiper-button-prev, .swiper-button-next{
             margin: 0 ;
             top: 0 ;
             left: 0;
             right: 0;
             bottom: 0;
         }
    .swiperCont{
         height: 72px;
         width: 456px;
         z-index: 300;
        
        
       .side{
           text-align: center;
           position: relative;
           width: 100%;
           height: 100%;
           img{
               width: 100%;
               height: 100%;
               border: 1px solid white;
               position: relative;
               object-fit: cover;
               cursor: pointer;
            //    margin: auto;
            //    left: 0;
            //    right: 0;
            
                // &.active{
                //     animation-play-state: running;
                // }
           }
        }
           
       }

</style>
<style lang='scss' scoped>
.swiperW{
   &_img{
       width: 516px;
       height: 350px;
       object-fit: cover;
   }
   .swiperGroup{
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
       &_left{
            width: 28px;
            height: 72px;
            background: #10406B;
            position: relative;
            &_icon{
                position: absolute;
                margin: auto;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                width: fit-content;
                height: fit-content;
            }
       }
       &_right{
            width: 28px;
            height: 72px;
            background: #10406B;
             position: relative;
            &_icon{
                position: absolute;
                margin: auto;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                width: fit-content;
                height: fit-content;
            }
       }
   }
}
</style>