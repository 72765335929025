<!--  -->
<template>
  <div class="echartsDetailsW">
          <div class="echartsDetailsW_title">{{title}}</div>
          <div class="echartsDetailsW_cont">{{obj.project.projectName}}-{{obj.building.buildingName}}</div>
          <div class="echartsDetailsW_map">
               <div id="echarts"></div>
          </div>
          

  </div>
</template>

<script>
// import * as echarts from 'echarts';
import * as echarts from 'echarts';
import { FormItem } from 'element-ui';

export default {
name: '',
  data () {
    return {
      
       opinionData3: [33, 12, 40, 14, "35", "33", "63", "11", "32"],
       opinionData1: [32, 30, 64, 11, 35, 43, 85, 61, 32],
       opinionData2: [12, 20, 34, 11, 15, 23, 66, 31, 25],
       dateObj:["2018.3","2018.4","2018.5","2018.6","2018.7","2018.8","2018.9","2018.10","2018.11"]
      //  opinionData: [33, 12, 40, 14, "35", "33", "63", "11", "32"],




    };
  },

  components: {},

  props: {
        title:{
        type: String,
        default: '',
        required: true
      },
      obj:{
        type: Object,
        default: ()=>{},
        required: true
      },
      eChartData:{
        type: Array,
        default: ()=>[],
        required: true
      },

      
  },

  computed: {},

  watch: {},

  methods: {
      	drawLine(id) {
				this.charts = echarts.init(document.getElementById(id))
				this.charts.setOption({
					tooltip: {
						trigger: 'axis',
            axisPointer: { type: 'cross' },
            formatter(params){
              // console.log(JSON.stringify(params))
               var res='<div class="cont1"><p>Date：<span class="font">'+params[0].name+'</span></p></div>' 
               let str=''
              for(var i=0;i<params.length;i++){
                  if(params[i].seriesName=='空置率走势'){
                    // console.log(params[i].color,'strstrstr333')
                     res+='<div class="cont1"><span style="border-radius:100%;margin-right:6px;display:block;width:10px;height:10px;background:'+params[i].color+'"></span><p>'+params[i].seriesName+':<span class="font">'+params[i].data+'%</span></p></div>'
                  }else{
                     
                     if(i==1)
                       str=params[i].data
                      //  console.log(str,'strstrstr')
                     if(i==2){
                        // console.log(str,'strstrstr111')
                        res+='<div class="cont1"><span style="border-radius:100%;margin-right:6px;display:block;width:10px;height:10px;background:'+params[i].color+'"></span><p>租金走势:<span class="font">'+params[i].data+'-'+str+'元/月.平</span></p>'

                     }
                    
                    
                  }
              }
              return res;

                  
            }
            // showContent: false

					},
          color: ['#80FFA5', '#B40000', '#B40000'],

					legend: {
						data: ['空置率走势',"租金走势",''],
            selectedMode:false
					},
					grid: {
						left: '3%',
						right: '4%',
						bottom: '13%',
						containLabel: true
					},

					toolbox: {
						feature: {
							saveAsImage: {}
						},
					},
					xAxis:[ {
            type: 'category',
            // axisTick: {
            //   alignWithLabel: true
            // },
            boundaryGap: false,
				  	data: this.dateObj
					
					}] ,
           yAxis: {
              type: 'value'
            },
					// yAxis:[
					// 	{
          //     type: 'value',
          //     name: '空置率走势',
          //     min: 0,
          //     max: 100,
          //     position: 'right',
          //     axisLabel: {
          //       formatter: '{value}%'
          //     }
          //   },
          //   {
          //     type: 'value',
          //     name: '',
          //     min: 0,
          //     max: 200,
          //     position: 'left',
          //     axisLabel: {
          //       formatter: '{value}'
          //     }
          //   },
          //    {
          //     type: 'value',
          //     name: '',
          //     min: 0,
          //     max: 200,
          //     position: 'left',
          //     axisLabel: {
          //       formatter: '{value}'
          //     }
          //   }
					// ],

					series: [
            {
						name: '空置率走势',
						type: 'line',
						// stack: 'Total',
            // areaStyle: {},
            // emphasis: {
            //   focus: 'series'
            // },
            //   label: {
            //   show: true,
            //   position: 'top'
            // },
            // yAxisIndex: 0,
						data: this.opinionData3
				  	},
             {
						name: '租金走势',
						type: 'line',
						// stack: 'Total',
            // areaStyle: {},
            // emphasis: {
            //   focus: 'series'
            // },
            // label: {
            //   show: true,
            //   position: 'top'
            // },
            // yAxisIndex: 2,
						data: this.opinionData1
					},
             {
						name: '',
						type: 'line',
						// stack: 'Total',
            // areaStyle: {},
            // emphasis: {
            //   focus: 'series'
            // },
            //   label: {
            //   show: true,
            //   position: 'top'
            // },
            // yAxisIndex: 1,
						data: this.opinionData2
					},
            
           
          ]
				})
			}
     
    //  this.charts.setOption({
    //           tooltip: {
    //             trigger: 'axis',
    //             axisPointer: { type: 'cross' }
    //           },
    //           legend: {},
    //           xAxis: [
    //             {
    //               type: 'category',
    //               axisTick: {
    //                 alignWithLabel: true
    //               },
    //               data:["2018.3","2018.4","2018.5","2018.6","2018.7","2018.8","2018.9","2018.10","2018.11"]
    //             }
    //           ],
    //           yAxis: [
    //             {
    //               type: 'value',
    //               name: '空置率',
    //               min: 0,
    //               max: 100,
    //               position: 'right',
    //               axisLabel: {
    //                 formatter: '{value}%'
    //               }
    //             },
    //             {
    //               type: 'value',
    //               name: '租金',
    //               min: 0,
    //               max: 4000,
    //               position: 'left',
    //               axisLabel: {
    //                 formatter: '{value}'
    //               }
    //             }
    //           ],
    //           series: [
    //             {
    //               name: '空置率',
    //               type: 'line',
    //               yAxisIndex: 0,
    //               data: [6, 32, 70, 86, 68.7, 10.7, 15.6, 11.2, 78.7, 48.8, 36.0, 19.3]
    //             },
    //             {
    //               name: '租金',
    //               type: 'line',
    //               smooth: true,
    //               yAxisIndex: 1,
    //               data: [3200, 12, 400, 140, 35, 335, 635, 1135, 3235]
    //             }
    //           ]
    //         })
    //     }

  },

  created(){},//生命周期 - 创建完成（可以访问当前this实例）
  mounted(){
          this.$nextTick(()=> {
            this.dateObj=[]
            this.opinionData1=[]
            this.opinionData2=[]
            this.opinionData3=[]
            // alert(JSON.stringify(this.eChartData))
            this.eChartData.reverse().forEach((item,index)=>{
                // console.log(item,'item')
                this.dateObj.push(item.month)
                this.opinionData1.push(item.rentH)
                this.opinionData2.push(item.rentL)
                this.opinionData3.push(item.vacancyRate)

                // console.log(this.opinionData1,'this.opinionData1')
                // console.log(this.opinionData2,'this.opinionData2')
                // console.log(this.opinionData3,'this.opinionData3')
             
          })
            // opinionData2: [12, 20, 34, 11, 15, 23, 66, 31, 25],
            // dateObj
			     	this.drawLine('echarts')
			})
  },//生命周期 - 挂载完成（可以访问DOM元素
  beforeCreate(){ },//生命周期 - 创建之前
  beforeMount(){ } ,//生命周期 - 挂载之前
  beforeUpdate(){ },//生命周期 - 更新之前
  updated(){ },//生命周期 - 更新之后
  beforeDestroy(){ },//生命周期 - 销毁之前
  destroyed(){ },//生命周期 - 销毁完成
  activated(){ }//如果页面有keep-alive缓存功能，这个函数会触发
}

</script>
<style lang='scss'>
.cont1{
  font-weight: 600;
  display: flex;
  justify-content: flex-start;
  align-items:center;
  color: #a8a8a8;
  margin: 6px 0;
  .font{
    color: #353535;
  }
}
</style>

<style lang='scss' scoped>
@import "@/assets/css/mixin";

 .echartsDetailsW{
    // padding: 20px 0 39px 0;
    width: $page-width;
    margin:auto;
    
    &_cont{
      text-align: center;
      margin-top: 40px;
      font-size: 28px;
      font-weight: 400;
      color: #676767;


    }
     &_title{
            font-size: 20px;
            font-weight: bold;
            color: #296ABC;
            border-bottom: 1px solid #10406B;
            padding-bottom: 10px;
        }
   &_map{
            // height: 300px;
            margin: 10px 0;
            #echarts{
                width: 1200px;
                height: 588px;
            }
        }
   
 }
</style>