<!--  -->
<template>
  <div class="roomMapW">
    　　 <el-amap vid="amapDemo" class="mapCont" :zoom="zoom" :center="center">
      <el-amap-marker v-for="(item,index) in recommendList" :key="index" :position="[item.longitude,item.latitude]"
        :vid="index">

        <div class="roomMapW_rect" @click="onClick(item)">
          <p>{{item.projectName}}</p>
          <i class="roomMapW_rect_triangle"></i>
          <div class="roomMapW_rect_name">
            <span>{{item.buildingName}}</span>
            <span>{{item.rentL}}-{{item.rentH}}元</span>

          </div>
        </div>

      </el-amap-marker>
    </el-amap>

    <div class="pageTop" id="div1">
      <div class="pageTop_search">
        <input class="pageTop_search_input" autocomplete=“off” @keyup="search_input" placeholder='请输入搜索关键字' type="text"
          v-model="keyword">
        <i v-show="this.keyword!=''" class="el-icon-circle-close pageTop_search_close" @click="clearKeyWord"></i>
        <div class="pageTop_search_icon" @click="searchBtn"><img src="@/assets/images/searchIcon.png" alt=""></div>
      </div>
      <div class="pageTop_subCont" v-show="rectList.length!=0">
        <CardSub class="pageTop_subCont_sub" v-for="(item,index) in rectList" :key="index" @clickRect="clickRectF"
          :infoList='item' :type="3"></CardSub>
      </div>

    </div>

  </div>

</template>

<script>
import {mapActions,mapGetters} from 'vuex' 
import CardSub from '@/components/Home/CardSub.vue'
import { getNewsList, getMock, getHousePagingList,getHouseList,isLogin} from '@/api/index'
export default {
name: '',
  data () {
    return {
        marker: [
            {
              position: [121.5273285, 31.21515044],
              events: {
                click: () => {
                  alert('click marker');
                },
                dragend: (e) => {
                  // console.log('---event---: dragend')
                  this.markers[0].position = [e.lnglat.lng, e.lnglat.lat];
                }
              },
              visible: true,
              draggable: false,
              template: '<span>1</span>',
            }
          ],
         keyword:'',
         recommendList:[],
         rectList:[],
        　zoom:13,
　　      zooms:[13,18],
　　      center:[102.719148,24.974782],
          label:{
                    content: '自定义标题',
                    offset:[-30,30]
                  }
　　
    };
  },

  components: {
    CardSub
  },

  props: {},

  computed: {
      ...mapGetters([
          'getKeyword'
      ]),
  },

  watch: {},

  methods: {
      ...mapActions([
          'setPageID',
          'setKeyword'
      ]),

      onClick(_obj){
        //  console.log(_obj,'_obj')
        // this.keyword = _obj.projectName 
        this.keyword = _obj.projectName +_obj.buildingName 

         this.rectList=[]
        //  this.rectList.push(_obj)
         this.apiHousePagingList(false)


        //  this.keyword=''
        //  this.$router.push({ name: 'RoomDetails', query: { id:_obj.id} });
        //  this.setPageID(5)

      },
      clickRectF(_obj){
        // console.log(_obj,'_obj_obj_obj')
        this.zoom=140
        this.center=[_obj.longitude,_obj.latitude]

      },
      
       clearKeyWord(){
         this.keyword=''
        //  this.apiHousePagingList()
         this.zoom=13
　　      this.center=[102.719148,24.974782]
         this.rectList = []
         document.getElementsByClassName('pageTop')[0].style.height = 'auto'
        
      },
      	search_input(e){
          //  console.log(e.keyCode,'e.keyCode·')
          //  console.log(this.keyword.trim().length,'data.trim().length')
          if(e.keyCode==13){
             this.apiHousePagingList()
          }
          // if(e.keyCode==32){
          //   this.keyword=''
          // }
          
      },
      searchBtn(){
        this.apiHousePagingList()
      },
    apiHousePagingList(_boo) {
      _boo != undefined ? _boo = _boo : _boo = true
      console.log(_boo,'_boo_boo')
      getHousePagingList({
        pageNo: 1, pageSize: 38, search: this.keyword
      })
        .then((data) => {
          if (data.code == 200) {

            console.log(data.data.records.length * 260, 'data.data.records.length * 260')
            console.log(document.documentElement.offsetHeight, 'ddocument.documentElement.offsetHeight')
            if (data.data.records.length * 260 > document.documentElement.offsetHeight) {
              document.getElementsByClassName('pageTop')[0].style.height='80%'
            } else { 
              document.getElementsByClassName('pageTop')[0].style.height = 'auto'
            }
            

            if (data.data.records.length == 0) {
              this.$message({
                message: '无搜索结果!',
                type: 'warning'
              });
              return
            }

            this.rectList = data.data.records


            //  this.recommendList[0].decorateStandard= "普通装修,毛坯,精装修,普通装修,普通装修,普通装修"
            if (_boo) { 
              this.zoom = 20
              this.center = [this.rectList[0].longitude, this.rectList[0].latitude]

            }
            

            setTimeout(() => { 
              // console.log(this.$refs.pageTop.style, 'hasodhaosdhoasd')
             
              console.log(document.getElementsByClassName('pageTop')[0].style.height, 'hasodhaosdhoasd')

              console.log(document.getElementsByClassName('roomMapW')[0].style.height, 'hasodhaosdhoasd11')
              
              
            },6000)
           

          } else {
            this.$message.error(data.message);
          }

          // this.playlist = data.playlist
        })
        .catch((err) => {
          console.log(err)
          this.$message.error(err);
        })  

          // getHouseList({search:this.keyword})
          //   .then((data) => {
          //     if(data.code==200){
          //       // console.log(data,'getHouseList')
          //       if(data.data.length==0){
          //         this.$message({
          //             message: '无搜索结果!',
          //             type: 'warning'
          //         });
          //           return
          //       }
                
          //       this.rectList=data.data
          //       //  this.recommendList[0].decorateStandard= "普通装修,毛坯,精装修,普通装修,普通装修,普通装修"
          //       this.zoom=20
          //       this.center=[this.rectList[0].longitude,this.rectList[0].latitude]

          //       }else{
          //       this.$message.error(data.message);
          //     }
            
          //     // this.playlist = data.playlist
          //   })
          //   .catch((err) => {
          //     console.log(err)
          //     this.$message.error(err);
          //   })  
        },

      pageResize(){
        this.$nextTick(()=>{
          var map = document.getElementsByClassName('roomMapW');
          // console.log(map[0].style.height,'map111111')
          map[0].style.height = document.documentElement.offsetHeight+ 'px'
        })
      }
  },

  created(){},//生命周期 - 创建完成（可以访问当前this实例）
  mounted(){
      this.setPageID(3)
      let _this = this;//赋值vue的this
      window.onresize = ()=>{
　　　　//调用methods中的事件
        _this.pageResize();
      }
      _this.pageResize();



       this.keyword=this.getKeyword
       if(this.keyword!='')
       this.apiHousePagingList()
       getHouseList()
            .then((data) => {
              if(data.code==200){
                // console.log(data,'getHouseList')
                this.recommendList=data.data

                }else{
                this.$message.error(data.message);
              }
            
              // this.playlist = data.playlist
            })
            .catch((err) => {
              console.log(err)
              this.$message.error(err);
            })  
  },//生命周期 - 挂载完成（可以访问DOM元素
  beforeCreate(){ },//生命周期 - 创建之前
  beforeMount(){ } ,//生命周期 - 挂载之前
  beforeUpdate(){ },//生命周期 - 更新之前
  updated(){ },//生命周期 - 更新之后
  beforeDestroy(){ },//生命周期 - 销毁之前
  destroyed(){
            window.onresize = null;
   },//生命周期 - 销毁完成
  activated(){ }//如果页面有keep-alive缓存功能，这个函数会触发
}

</script>
<style lang='scss'>
// .amap-icon{
//   display: none;
// }
</style>
<style lang='scss' scoped>
@import "@/assets/css/mixin";

.roomMapW{
    width: 100%;
    // height: 90% !important;
    text-align: center;
    color: black;
    position: absolute;
    top: 0;
    left: 0;
    // .amap-labels{
    //     height: 100%;
    // }
   &_rect{
     background: #96A8BF;
     padding: 6px 10px;
     color: white;
     font-size: 14px;
    //  width: 100px;
     border-radius: 11px;
     white-space: nowrap;
     text-align: center;
     box-shadow: 1px 1px 8px #888888;

     &:hover{
       background: #296ABC;
       .roomMapW_rect_triangle{
        border-top: 10px solid #296ABC;

       }

     }
    //  box-sizing: border-box;
     p{
       text-align: center;

     }
     &_triangle{
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 10px solid #96A8BF;
        position: absolute;
        bottom: -6px;
       

     }
     &_name{
       display: flex;
       justify-content: space-between;
       align-items: center;
       span:nth-child(2){
          font-weight: bold;
          margin-left: 30px;
       }

     }
   }

    .mapCont{

    }

     .pageTop{
         width: $page-width;
         margin:33px auto;
        //  display: flex;
        //  justify-content: space-between;
        //  align-items: center;
         height: auto;
         position: absolute;
         left: 40px;
         top: 70px;
         width: 536px;
         
        
         &_subCont{
          //  overflow-y: scroll;
           height: 96%;
           margin-top: 10px;
           overflow-x: hidden;

            &_sub{
            margin: -13px 0;
            transform: scale(.88);
            margin-left: -36px;

            }

         }
         &_search{
             width: 521px;
             height: 56px;
             box-shadow: 0px 3px 13px rgba(0, 0, 0, 0.1);
             border-radius: 10px;
             background: white;
             box-sizing: border-box;
              display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;
            &_close{
                cursor: pointer;
                position: absolute;
                left: 438px;
                top: 18px;
            }
            &_input{
                width: 410px;
                height: 56px;
                border: none;
                margin-left: 20px;
                background: none;
                outline:none;
                color: #636363;
                
            }
                input::-webkit-input-placeholder {
                    /* placeholder颜色  */
                        color: rgba(51, 51, 51,0.25);
                    /* placeholder字体大小  */
                        font-size: 18px;
                    /* placeholder位置  */    
                    }
            
                &_icon{
                    cursor: pointer;
                    width: 56px;
                    height: 56px;
                    background: #296ABC;
                    border-radius: 0 10px 10px 0;
                    position: relative;
                    
                    img{
                        width: 24px;
                        height: 24px;
                        position: absolute;
                        margin: auto;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                    }

                
                }
             
         }
    }


    
}
</style>