<!--  -->
<template>
  <div class="roomDetailsW">
      <div class="pageW">
          <div class="pageW_title"> <span @click="goBack">{{pageTitle}}</span>  <span>></span> <span>{{apiData.project.projectName}}</span> </div>


          <div class="pageSub">
            <div class="page1">
                <SwiperImg class="page1_left" :imageUrls="imageUrls"></SwiperImg>
                <div class="page1_right">
                    <div class="infoT">
                        <div class="infoT_title">{{apiData.project.projectName}}</div>  
                        <div class="infoT_num">{{apiData.building.buildingName}}</div>
                    </div>
                     <div class="infoC">
                        <div class="infoC_title">当前租金:</div>  
                        <div class="infoC_num"><span class="infoC_num_red">{{apiData.buildingOper.rentL}}-{{apiData.buildingOper.rentH}}</span>元/月·平</div>
                    </div>
                    <div class="infoR">查看历史行情 <img class="infoR_img" src="@/assets/images/icon.jpg" alt=""></div>

                     <div class="infoC">
                        <div class="infoC_title">当前售价:</div>  
                        <div class="infoC_num"><span class="infoC_num_red">{{apiData.buildingOper.saleL}}-{{apiData.buildingOper.saleH}}</span>元/平</div>
                    </div>
                    <div class="infoR">查看历史行情 <img class="infoR_img" src="@/assets/images/icon.jpg" alt=""></div>

                     <div class="infoC">
                        <div class="infoC_title">物业费:</div>  
                        <div class="infoC_num"><span class="infoC_num_red">{{apiData.buildingOper.propertyFee}}</span>元/月·平</div>
                    </div>
                     <div class="infoC" v-show="this.getIsLogin">
                        <div class="infoC_title">空置率:</div>  
                        <div class="infoC_num"><span class="infoC_num_black">{{apiData.buildingOper.vacancyRate}}</span>%</div>
                    </div>
                    <div class="infoC" v-show="this.getIsLogin">
                        <div class="infoC_title">总空置面积:</div>  
                        <div class="infoC_num"><span class="infoC_num_black">{{apiData.buildingOper.vacancyArea}}</span>平</div>
                    </div>
                    <div class="infoC" v-show="this.getIsLogin">
                        <div class="infoC_title">单层最大连续可租面积:</div>  
                        <div class="infoC_num"><span class="infoC_num_red">{{apiData.buildingOper.rentAreaH}}</span>平</div>
                    </div>
                </div>
            </div>
           
          </div>


          <div class="pageSub1">

            <div class="introCont">
                <div class="introCont_title">项目简介</div>
                <div class="introCont_content">{{apiData.project.intro}}</div>
                <div class="introCont_link" @click="goUrl(apiData.project.arUrl)"></div>
            </div>

            <CardDetails class="card1" :type='"1"' :tagList="tagList1" :title="'项目基本信息'" :leftList="leftList" :rightList="rightList"></CardDetails>
            <CardDetails class="card1" :type='"1"' :tagList="[]" :title="'本楼栋数据'" :leftList="leftList1" :rightList="rightList1"></CardDetails>
            <CardDetails class="card1" :type='"2"' :tagList="[]" v-if="contentArr1.length!=0" v-show="this.getIsLogin" :title="'楼层可租/售详情'" @viewAreaPic="viewAreaPic" :contentArr="contentArr1" :leftList="leftList1" :rightList="rightList1"></CardDetails>
            <CardDetails class="card1" :type='"3"' :tagList="[]" v-if="contentArr2.length!=0" :title="'实际入住商户一览'"  :contentArr="contentArr2" :leftList="leftList1" :rightList="rightList1"></CardDetails>
            <MapDetails class="map" :title="'项目配套'" :data="apiData" :id="this.$route.query.id"></MapDetails>
            <EchartsDetails v-if="eChartData.length!=0" class="echart" :title="'历史数据统计'" :obj="apiData" :eChartData="eChartData"></EchartsDetails>

          </div>


      </div>

       <el-dialog :visible.sync="loginVisible" :show-close="false">
            <div class="dialogCont">        
                <img class="dialogCont_bgImg"  src="../assets/images/hu.jpg" alt="">
            </div>
        </el-dialog>

  </div>

   

</template>



<script>
import SwiperImg from '@/components/RoomDetails/SwiperImg.vue'
import CardDetails from '@/components/RoomDetails/CardDetails.vue'
import MapDetails from '@/components/RoomDetails/MapDetails.vue'
import EchartsDetails from '@/components/RoomDetails/EchartsDetails.vue'

import {getNewsList,getMock,getHouseHistory,getHouseDetailed,isLogin} from '@/api/index'
import {mapGetters,mapActions} from 'vuex' 
import { setLocalStorage,getLocalStorage } from '../tools/tools'
import mode from '@/api/modeType'


export default {
name: '',
  data () {
    return {
        eChartData:[],
        preRoute:'',
        pageTitle:'首页资讯',
        imageUrls:[],
        loginVisible:false,
        tagList1:[],
        contentArr1:[],
        contentArr2:[
            ['1层','云南闽西茶业有限公司','53144587798955455214','是'],
            ['1层','云南闽西茶业有限公司','53144587798955455214','是'],
             ['1层','云南闽西茶业有限公司','53144587798955455214','是'],
            ['1层','云南闽西茶业有限公司','53144587798955455214','是'],
             ['1层','云南闽西茶业有限公司','53144587798955455214','是'],
            ['1层','云南闽西茶业有限公司','53144587798955455214','是'],
             ['1层','云南闽西茶业有限公司','53144587798955455214','是'],
            ['1层','云南闽西茶业有限公司','53144587798955455214','是'],
             ['1层','云南闽西茶业有限公司','53144587798955455214','是'],
            ['1层','云南闽西茶业有限公司','53144587798955455214','是'],
            ['1231层','云南闽西茶业有限123公司','5314458721798955455214','是']
        ],
        apiData:{
            'project':{},
            'building':{},
            'buildingOper':{},
            'floorOpers':[]
        },
        leftList:[
            {'title':'项目名称:','content':'自贸数字港'},
            {'title':'地址:','content':'彩云北路3567号'},
            {'title':'类型:','content':'商业综合体'},
            {'title':'建成年份:','content':'2019年'},
            {'title':'项目总建筑面积:','content':'40909㎡'}
            ],
        rightList:[
            {'title':'开发商:','content':'昆明鼎杰房地产开发有限公司'},
            {'title':'物业公司:','content':'云南大都物业服务有限公司'}
            // {'title':'物业电话:','content':'0871-65000000'}
            ],
        leftList1:[
        {'title':'本栋建筑面积:','content':'6620.52㎡'},
        {'title':'建筑高度:','content':'120㎡'},
        {'title':'标准单层面积:','content':'1866.24㎡'},
        {'title':'层高:','content':'4.5m'},
        {'title':'地上/地下层数:','content':'37层/4层'},
        {'title':'地上停车位/地下停车位:','content':'80个/2336个'}
        ],
    rightList1:[
        {'title':'客梯/货梯数量:','content':'8部/2部'},
        {'title':'大堂面积:','content':'1200㎡'},
        {'title':'大堂层高:','content':'6.5m'},
        {'title':'运营模式:','content':'全部自持'},
        {'title':'物业联系人/电话:','content':'李华平,13888777330'},
        {'title':'招商联系人/电话:','content':'孙,13888777330'}
        ]
    };
  },




  components: {
      SwiperImg,
      CardDetails,
      MapDetails,
      EchartsDetails
  },

  props: {},

  computed: {
       ...mapGetters([
          'getPageID',
          'getIsLogin',
      ])
  },

  watch: {
     
    //  $route(to, from) {
    //      console.log(from,'beforeRouteEnterbeforeRouteEnter')
    //     if(from.name=='Home')
    //      this.pageTitle='首页资讯'

    //     if(from.name=='RoomQuery')
    //      this.pageTitle='楼宇查询'
         
    //      if(from.name=='RoomQuery')
    //      this.pageTitle='楼宇查询'
    //   },
     
      getIsLogin(newValue,oldValue){


            //  console.log(newValue,'newValuenewValuenewValue')  
              if(newValue==true){
                 this.houseDetailedF()

              }else{
                  this.contentArr1=[]
                  this.eChartData=[]
              }
            
          }

      
  },

  methods: {
       ...mapActions([
          'setPageID',
      ]),
      goUrl(_url){
           window.open(_url)
      },
      goBack(){
        // this.preRoute
           this.$router.push({ name: this.preRoute});

      },
      viewAreaPic(){
        //   console.log('viewAreaPic')
          this.loginVisible=true
      },
      houseDetailedF(){

           getHouseHistory({id:this.$route.query.id})
                .then((data) => {

                 if(data.code==200){
                    // console.log(data,'getHouseHistory')
                    this.eChartData=data.data
                   }else{
                       this.$message.error(data.message);
                   }
               
                // this.playlist = data.playlist
                })
                .catch((err) => {
                    console.log(err)
                    this.$message.error(err);
                })  

          getHouseDetailed({id:this.$route.query.id})
                .then((data) => {
                
                   if(data.code==200){
                    //    console.log(data,'getHouseDetailed')
                       
                       this.apiData=data.data

                    //    this.dataD=data.data

                    //    alert(this.apiData.buildingOper.vacancyRate)

                       if(this.apiData.project.ifFreeTrade=='是')
                       this.tagList1.push('自贸区楼宇')
                        
                        if(this.apiData.building.identifiedLevel)
                       this.tagList1.push(this.apiData.building.identifiedLevel)

                    //    console.log(this.tagList1,'this.tagList1this.tagList1')


                        let arr1=['tenantName','tenantCode','industry','floorName','ipReg']
                        this.contentArr2=[]
                       
                       if(this.apiData.tenantOpers.length!=0){
                            this.apiData.tenantOpers.forEach((elem, index) => {
                                // console.log(elem,'ddd')
                                 let objArr1=[]

                                  arr1.forEach((elemSon, index) => {
                                   for(var p in elem){
                                          if(elemSon==p){
                                             objArr1.push(elem[p])
                                           }
                                         }
                                      });
                                  
                                    // console.log(objArr1,'objArr1333333')
                                    this.contentArr2.push(objArr1)

                                });
                                // console.log(this.contentArr2,'contentArr2')

                       }else{
                           this.contentArr2=this.apiData.tenantOpers
                       }

                       //contentArr2

                       let arr=['floorName','rentalMethod','constructionArea','decorateStandard','purpose','rentTotal','rentMax','rentMin','saleTotal','saleMax','saleMin']
                       if(this.apiData.floorOpers){
                            this.contentArr1=[]
                            this.apiData.floorOpers.forEach((elem, index) => {
                                // console.log(elem,'ddd')
                                 let objArr=[]

                                  arr.forEach((elemSon, index) => {
                                   for(var p in elem){
                                          if(elemSon==p){
                                             objArr.push(elem[p])
                                           }
                                         }
                                      });
                                    // if(elem.rentTotal!=null && elem.saleTotal!=null)   

                                    // objArr.splice(5,0,Math.floor((elem.rentTotal+elem.saleTotal)/elem.constructionArea*100)+'%')   

                                    console.log(Math.max(elem.rentTotal,elem.saleTotal),'dddddcao')

                                     objArr.splice(5,0,Math.floor((Math.max(elem.rentTotal,elem.saleTotal)/elem.constructionArea*100))+'%')  

                                    // else
                                    // objArr.splice(5,0,0+'%')  


                                    // console.log(objArr,'objArrobjArrobjArr1')
                                    this.contentArr1.push(objArr)
                                     

                                  
                                });
                                // console.log(this.contentArr1,'contentArr1')
                                

                       }
                      
                       
                       this.imageUrls=this.apiData.imageUrls
                    //    this.imageUrls=['http://zj-zone.com/img/1.jpg','http://zj-zone.com/img/2.jpg','http://zj-zone.com/img/3.jpg','http://zj-zone.com/img/4.jpg','http://zj-zone.com/img/5.jpg','http://zj-zone.com/img/6.jpg']
                    //   this.imageUrls=[' https://dev.zixel.cn/img/1.jpg',' https://dev.zixel.cn/img/2.jpg','https://dev.zixel.cn/img/3.jpg','https://dev.zixel.cn/img/4.jpg','https://dev.zixel.cn/img/5.jpg','https://dev.zixel.cn/img/6.jpg']



                       this.leftList=[
                            {'title':'项目名称:','content':(this.apiData.project.projectName || mode.defStr)},
                            {'title':'地址:','content':(this.apiData.project.address || mode.defStr)},
                            {'title':'类型:','content':(this.apiData.project.projectCategory || mode.defStr)},
                            {'title':'建成年份:','content':(this.apiData.project.setupTime || mode.defStr)},
                            {'title':'项目总建筑面积:','content':(this.apiData.project.floorArea || mode.defStr)+'㎡'}
                            ],
                        this.rightList=[
                            {'title':'开发商:','content':(this.apiData.project.developVendor || mode.defStr)},
                            {'title':'物业公司:','content':(this.apiData.project.propertyVendor || mode.defStr)}
                            // {'title':'物业电话:','content':'0871-65000000'}
                            ],
                        this.leftList1=[
                            {'title':'本栋建筑面积:','content':(this.apiData.building.constructionArea || mode.defStr)+'㎡'},
                            {'title':'建筑高度:','content':(this.apiData.building.constructionHeight || mode.defStr)+'m'},
                            {'title':'标准单层面积:','content':(this.apiData.building.standardArea || mode.defStr)+'㎡'},
                            {'title':'层高:','content':(this.apiData.building.floorHeight || mode.defStr)+'m'},
                            {'title':'地上/地下层数:','content':(this.apiData.building.groundFloorCount || mode.defStr)+'层/'+(this.apiData.building.undergroundFloorCount || mode.defStr)+'层'},
                            {'title':'地上停车位/地下停车位:','content':(this.apiData.project.groundParkCount || mode.defStr)+'个/'+(this.apiData.project.undergroundParkCount || mode.defStr)+'个'}
                            ],
                       this.rightList1=[
                            {'title':'客梯/货梯数量:','content':(this.apiData.building.customerElevatorCount || mode.defStr)+'部/'+(this.apiData.building.freightElevatorCount || mode.defStr)+'部'},
                            {'title':'大堂面积:','content':(this.apiData.building.lobbyArea || mode.defStr)+'㎡'},
                            {'title':'大堂层高:','content':(this.apiData.building.lobbyHeight || mode.defStr)+'m'},
                            {'title':'运营模式:','content':(this.apiData.building.operatingMethod || mode.defStr)},
                            {'title':'物业联系人/电话:','content':(this.apiData.building.propertyContactMan || mode.defStr)+','+(this.apiData.building.propertyContactTel || mode.defStr)},
                            {'title':'招商联系人/电话:','content':(this.apiData.building.attractContactMan || mode.defStr)+','+(this.apiData.building.attractContactTel || mode.defStr)}
                            ]
                    //    alert( this.rightList1.length)

                   }else{
                       this.$message.error(data.message);
                   }
                })
                .catch((err) => {
                   console.log(err)
                   this.$message.error(err);
                })  

      }
      
            
  },

  created(){},//生命周期 - 创建完成（可以访问当前this实例）
  mounted(){
    //    console.log(this.$route,'mountedmountedmounted b')
    //    console.log(getLocalStorage('routeGD'),'routeGD b')
    //    console.log(getLocalStorage('titleGD'),'titleGD b')
       this.setPageID(5)
        
       
        if(getLocalStorage('routeGD'))
          this.preRoute=getLocalStorage('routeGD')

        if(getLocalStorage('titleGD'))
        this.pageTitle=getLocalStorage('titleGD')
         

       if(this.$route.query.id){
            this.houseDetailedF()

            

       }
       


  },//生命周期 - 挂载完成（可以访问DOM元素
   beforeRouteEnter (to, from, next) {
    next(vm => {
    	//  console.log(from,'beforeRouteEnterbeforeRouteEnter')
        

         if(from.name==null)
         return

         vm.preRoute=from.name
         
        if(from.name=='Home')
         vm.pageTitle='首页资讯'

        if(from.name=='RoomQuery')
         vm.pageTitle='楼宇查询'
         
         if(from.name=='RoomMap')
         vm.pageTitle='楼宇地图'

         setLocalStorage('routeGD',vm.preRoute)
         setLocalStorage('titleGD',vm.pageTitle)
 	 })
  },
//  beforeRouteUpdate (to, from, next) {
//        next(vm => {
//     	 console.log(from,'beforeRouteEnterbeforeRouteEnter')
//         if(from.name=='Home')
//          this.pageTitle='首页资讯'

//         if(from.name=='RoomQuery')
//          this.pageTitle='楼宇查询'
         
//          if(from.name=='RoomQuery')
//          this.pageTitle='楼宇查询'
//  	 })
      
//     },
  beforeCreate(){ },//生命周期 - 创建之前
  beforeMount(){ } ,//生命周期 - 挂载之前
  beforeUpdate(){ },//生命周期 - 更新之前
  updated(){ },//生命周期 - 更新之后
  beforeDestroy(){ },//生命周期 - 销毁之前
  destroyed(){ },//生命周期 - 销毁完成
  activated(){ }//如果页面有keep-alive缓存功能，这个函数会触发
}

</script>

<style lang="scss" >
.roomDetailsW{
.el-dialog{
    position: static;
    background: none;
    border: none;
    box-shadow:none;
  .dialogCont{
        width: 86% !important;
        height: 86% !important;
        background: rgba($color: #ffffff, $alpha: 1) !important;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        border-radius: 3px;
        overflow: hidden;
        z-index: 100;
        &_bgImg{
            object-fit: contain;
            width: 100%;
            height: 100%


        }
    }
}
}

</style>
<style lang='scss' scoped>
@import "@/assets/css/mixin";
 

.roomDetailsW{
    background:$background-color;
    overflow: hidden;
    position: relative;
    .pageW{
         
         &_title{
            font-size: 20px;
            color: #8D8D8D;
            margin: 30px auto 12px auto;
            width: $page-width;
             span:nth-child(1){
                cursor: pointer;
            }
            span:nth-child(2){
                font-size: 14px;
                top: -3px;
                position: relative;
                margin: 0 6px;
            }
            span:nth-child(3){
                color: #316FA4;
            }
         }
         .pageSub{
           background: white;
         }
         .page1{
             display: flex;
             justify-content: space-between;
             padding: 20px 0 39px 0;
             width: $page-width;
             margin:auto;
            &_left{


            }
            &_right{
                color: #333333;
                flex: 1;
                margin-left: 84px;
               
                // justify-content: space-between;
                // align-items:stretch;
                >div{
                    margin: 3px 0;
                }
                .infoT{
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;
                    padding: 10px 0;
                    box-sizing: border-box;
                    border-bottom: 1px solid #10406B;

                    &_title{
                        font-size: 26px;
                        font-weight: bold;
                        color: #333333;
                        }
                    &_num{
                        font-size: 20px;
                        font-weight: 400;
                        color: #333333;
                        }    
                }

                .infoC{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 10px 0;
                    box-sizing: border-box;

                    &_title{
                        font-size: 20px;
                        font-weight: bold;
                        color: #676767;
                        }
                    &_num{
                        font-size: 16px;
                        font-weight: 400;
                        color: #061421;
                            &_black{
                               font-size: 28px;
                               color: #061421;
                            }
                            &_red{
                                font-size: 28px;
                                color: #B50000;
                            }
                        }    
                }
                .infoR{
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    font-size: 16px;
                    font-weight: 400;
                    color: #79AAD5;
                    &_img{
                        width: 16px;
                        height: 16px;
                        }
                    
                }
            }
         }

         .pageSub1{
           background: white;
           margin-top:20px;
           overflow: hidden;
           .card1{
               margin-bottom: 71px;
           }
           .map {
               margin-bottom: 71px;
           }
           .echart{
               margin-bottom: 71px;
           }
           .introCont{
                padding: 20px 0 39px 0;
                width: $page-width;
                margin:auto;
                margin-top: 15px;
                position: relative;
                &_link{
                    width: 100px;
                    height: 40px;
                    line-height: 9rem;
                    background: url('../assets/images/360.png') no-repeat center ; 
                    background-size: contain;
                    // margin: 0 .8rem;
                    cursor: pointer;
                    position: absolute;
                    top: 16px;
                    right: 0px;
                    &:hover{
                    opacity: .9;
                    }

                }
                &_title{
                    font-size: 20px;
                    font-weight: bold;
                    color: #296ABC;
                    border-bottom: 1px solid #10406B;
                    padding-bottom: 10px;
                }
                &_content{
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 30px;
                    color: #333333;
                    margin-top: 22px;
                    text-indent:36px;
                }
           }
         }
    }
}
</style>