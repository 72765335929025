<!--  -->
<template>
  <div class="nwsPageW">

      <div class="pageTop">
          <img class="pageTop_img" src="@/assets/images/logoColour.png">  
          <div class="pageTop_search" >
               <input class="pageTop_search_input" autocomplete=“off” @keyup="search_input"  placeholder='请输入搜索关键字' type="text" v-model="keyword">
               <i v-show="this.keyword!=''" class="el-icon-circle-close pageTop_search_close" @click="clearKeyWord"></i>
               <div class="pageTop_search_icon" @click="searchBtn"><img src="@/assets/images/searchIcon.png" alt="" ></div>
          </div>
      </div>

     <div class="pageBody">
         <ul class="pageBody_left">
              <li  class="pageBody_left_btn" v-for="(item,index) in menulist" :key="index" :ref="`btn${index+1}`" @click="changePage(index+1)">{{item}}</li>
         </ul>

         <div class="pageBody_right" ref="bodyRight">
            <div class="pageBody_right_title"><span @click="gohome">首页资讯</span><span class="pageBody_right_title_icon">></span>  <span class="pageBody_right_title_txt" ref="title" @click="goList">{{title1}}</span> <span v-show="changeID==2" class="pageBody_right_title_icon">></span> <span class="pageBody_right_title_txt" v-show="changeID==2">{{title2}}</span></div>
          
            <ul class="list" v-show="changeID==1">
                <li v-show="newlist.length!=0" class="item" v-for="(item,index) in newlist" :key="index" @click="changeNews(item.id)">
                    <div class="item_sub">
                        <div class="item_sub_title">{{item.title}}</div>
                        <div class="item_sub_time">{{item.publishDate}}</div>
                    </div>
                    <div class="item_content">{{item.content==null?'暂无详情':item.content}}</div>
                </li>
                <li class="otherItem" v-show="newlist.length==0">暂无相关内容!</li>

            </ul>

            <div class="detailsNews" v-show="changeID==2">
                 <div class="detailsNews_sub">
                        <div class="detailsNews_sub_title">{{detailsObj.title}}</div>
                        <div class="detailsNews_sub_time">{{detailsObj.publishDate}}</div>
                    </div>
                 <div class="detailsNews_content" v-html="detailsObj.content"></div>

            </div>

            <div>

            </div>

              <div class="pageBody_pageination" v-if="paginationIsVisible">
                <el-pagination
                    background
                    layout="prev, pager, next"
                    :total="newTotal"
                    :page-size="newPageSize"
                    :current-page="newCurrentPage"
                    @current-change="currentChange"
                    >
                </el-pagination>
             </div>

         </div>
          
        

     </div>

  </div>
</template>

<script>
import {mapActions,mapGetters} from 'vuex' 
import {getNewsList,getMock,getNewsPagingList,getNewsDetailed} from '@/api/index'

export default {
name: '',
  data () {
    return {
        newTotal:30,
        newPageSize:6,
        newCurrentPage:1,
        paginationIsVisible:false,


        changeID:1,
        keyword:'',
        preId:0,
        menulist:['招商信息','楼宇资讯','招商政策','楼宇规划'],
        title1:'招商信息',
        title2:'',
        newlist:[
            {title:'官渡区重点片区和项目推介1',time:'2020-09-23',content:'春暖花开日，合作共赢时在4月22日召开的“传递城市价值、擎动官渡未来”产业投资推介会上，擎动官渡未来”产业投资推介会上'},
            {title:'官渡区重点片区和项目推介2',time:'2020-09-23',content:'春暖花开日，合作共赢时在4月22日召开的“传递城市价值、擎动官渡未来”产业投资推介会上，擎动官渡未来”产业投资推介会上'},
            {title:'官渡区重点片区和项目推介3',time:'2020-09-23',content:'春暖花开日，合作共赢时在4月22日召开的“传递城市价值、擎动官渡未来”产业投资推介会上，擎动官渡未来”产业投资推介会上'},
            {title:'官渡区重点片区和项目推介4',time:'2020-09-23',content:'春暖花开日，合作共赢时在4月22日召开的“传递城市价值、擎动官渡未来”产业投资推介会上，擎动官渡未来”产业投资推介会上'},
            {title:'官渡区重点片区和项目推介5',time:'2020-09-23',content:'春暖花开日，合作共赢时在4月22日召开的“传递城市价值、擎动官渡未来”产业投资推介会上，擎动官渡未来”产业投资推介会上'},
            {title:'官渡区重点片区和项目推介6',time:'2020-09-23',content:'春暖花开日，合作共赢时在4月22日召开的“传递城市价值、擎动官渡未来”产业投资推介会上，擎动官渡未来”产业投资推介会上'},
            {title:'官渡区重点片区和项目推介7',time:'2020-09-23',content:'春暖花开日，合作共赢时在4月22日召开的“传递城市价值、擎动官渡未来”产业投资推介会上，擎动官渡未来”产业投资推介会上'},
            {title:'官渡区重点片区和项目推介8',time:'2020-09-23',content:'春暖花开日，合作共赢时在4月22日召开的“传递城市价值、擎动官渡未来”产业投资推介会上，擎动官渡未来”产业投资推介会上'},
            {title:'官渡区重点片区和项目推介',time:'2020-09-23',content:'春暖花开日，合作共赢时在4月22日召开的“传递城市价值、擎动官渡未来”产业投资推介会上，擎动官渡未来”产业投资推介会上'}
            ],
        detailsObj:{title:'官渡区重点片区和项目推介',publishDate:'2020-09-23',content:'春暖花开日，合作共赢时。在4月22日召开的“传递城市价值、擎动官渡未来”产业投资推介会上，官渡区委书记和丽川为推介会致辞，诚挚邀请广大企业家来官渡投资兴业，共谋发展。官渡区委副书记、代理区长周燕详细介绍了官渡区招商引资重点片区和项目，包括一核引领——“巫家坝总部基地核”“四区”中的三个重点区域和产业投资地图中的“两翼”，为企业家到官渡热土考察置业绘制蓝图，提供政策红利。一核引领——“巫家坝总部基地核”巫家坝片区核心区面积15.87平方公里，北连呼马山，南接滇池，靠山连水，通达四方。片'},
    
    };
  },

  components: {},

  props: {},

  computed: {
       ...mapGetters([
          'getPageID',
      ]),
  },

  watch: {
     keyword(newValue,oldValue){
         if(newValue.length==0){
            //  this.newlist=[]
             if(this.preId==5){
                this.newlist=[]
                // console.log('dddddddd')
             }else{
                // console.log(this.menulist.length,'this.keyword3333')

                // if(_this.menulist.length==5)
                //    this.menulist.pop()
                //  console.log(this.menulist,'this.keywor66666')

             }
                
         }
     }
  },

  methods: {
       ...mapActions([
          'setPageID',
      ]),
      gohome(){
        this.setPageID(1)
        this.$router.push({ path:'/'})
      },
      searchBtn(){
          if(this.keyword=='')
            return
          if(this.menulist.length==4)
            this.menulist.push('搜索结果')
        //  this.newlist=[]
            setTimeout(()=>{
                this.changePage(5)
            },300)
      },
      clearKeyWord(){
        //   console.log(this.keyword,'this.keyword')
         this.keyword=''
        

         if(this.menulist.length==5 && this.keyword=='')
          this.menulist.pop()

          if(this.preId==5){
               this.newlist=[]
             this.changePage(1)
          }else{
              this.changePage(this.preId)

          }
          
      },

      search_input(e){
        
          if(e.keyCode==13){
             this.searchBtn()
          }
          // if(e.keyCode==32){
          //   this.keyword=''
          // }
          
      },

      changePage(_id){
        //    console.log(_id,'_id')
        //    console.log(this.preId,'this.preId')


        if(_id==this.preId && _id<5)
           return

  
         this.$refs[`btn${_id}`][0].classList.add('active')
         if(this.preId!=0 && _id!=this.preId)
             this.$refs[`btn${this.preId}`][0].classList.remove('active')
         this.preId=_id


        //  console.log(this.menulist,'this.keyword222221')

         this.title1=this.menulist[_id-1]
        //  console.log(this.keyword,'this.keyword111')

         this.changeID=1

         this.$refs.title.classList.add('active')



         if(_id!=5 && this.menulist.length==5 && this.keyword=='')
          this.menulist.pop()
        
        
        let str
        if(_id<5)
         str=this.menulist[_id-1]
        else
         str=this.keyword

        this.newCurrentPage=1
        this.getNewsPagingListF(this.newCurrentPage,this.newPageSize,str,_id)

      },
      currentChange(val){
        //   console.log(`当前页: ${val}`);
        //   console.log(this.newCurrentPage,'newCurrentPage')
          this.newCurrentPage=val
          this.getNewsPagingListF(this.newCurrentPage,this.newPageSize,this.menulist[this.preId-1],this.preId)

      },
      changeNews(_id){
        //   console.log(_id)
          this.getNewsDetailedF(_id)
      },
      goList(){
        //  console.log(this.preId,'this.preId') 
         this.changeID=1
         this.getNewsPagingListF(this.newCurrentPage,this.newPageSize,this.menulist[this.preId-1],this.preId)
         this.$refs.title.classList.add('active')

      },
      getNewsPagingListF(_pageNo,_pageSize,_contentType,_id){
         let obj={}
         if(_id<5){
             obj={pageNo:_pageNo, pageSize:_pageSize,contentType:_contentType}
         }else{
             obj={pageNo:_pageNo, pageSize:_pageSize,search:this.keyword}
         }
       
           getNewsPagingList(obj)
                .then((data) => {

                   if(data.code==200){
                        // console.log(data,'getNewsPagingList')
                        this.newTotal=data.data.total
                        this.newlist=data.data.records

                        this.newTotal>this.newPageSize?this.paginationIsVisible=true:this.paginationIsVisible=false
                        this.$refs.bodyRight.classList.remove('autoH')

                   }else{
                       this.$message.error(data.message);
                   } 
               

                // this.playlist = data.playlist
                })
                .catch((err) => {
                    console.log(err)
                    this.$message.error(err);
                })  
      },
      getNewsDetailedF(_id){
        //   console.log(_id,'_id_id_id')
           getNewsDetailed({id:_id})
                .then((data) => {

                 if(data.code==200){
                    // console.log(data,'getNewsDetailed')
                    this.detailsObj=data.data
                    this.title2=data.data.title
                    this.paginationIsVisible=false

                    this.changeID=2
                    this.$refs.title.classList.remove('active')
                    this.$refs.bodyRight.classList.add('autoH')


                   }else{
                       this.$message.error(data.message);
                   }
               
                // this.playlist = data.playlist
                })
                .catch((err) => {
                    console.log(err)
                    this.$message.error(err);
                })  
      }
  },

  created(){
    //   console.log('createdcreatedcreated')

  },//生命周期 - 创建完成（可以访问当前this实例）
  mounted(){
       this.setPageID(4)

    //    console.log('mountedmountedmounted')
    //    console.log(this.$route.query.id,'this.$route.query.id')
    //    console.log(this.$route.query.type,'this.$route.query.type')

    
      if(this.$route.query.id!=undefined && this.$route.query.type!=undefined){
        //    console.log('111111')
           this.$refs[`btn${this.$route.query.type}`][0].classList.add('active')
           this.title1=this.menulist[this.$route.query.type-1]
           this.changeID=2
           this.preId=this.$route.query.type
           this.getNewsDetailedF(this.$route.query.id)
          return
      }
      if(this.$route.query.type){
            // console.log('2222')
            this.changePage(this.$route.query.type)
      }

    //   if(this.$route.params=='Assetstore')
    //    this.
    //   this.$refs[`btn${this.preId}`][0].classList.add('active')



    

    //   this.setPageID(4)

  },//生命周期 - 挂载完成（可以访问DOM元素
  beforeCreate(){ },//生命周期 - 创建之前
  beforeMount(){ } ,//生命周期 - 挂载之前
  beforeUpdate(){ },//生命周期 - 更新之前
  updated(){ },//生命周期 - 更新之后
  beforeDestroy(){ },//生命周期 - 销毁之前
  destroyed(){ },//生命周期 - 销毁完成
  activated(){ }//如果页面有keep-alive缓存功能，这个函数会触发
}

</script>
<style lang='scss' >
.pageBody_pageination{
   display: flex;
    justify-content: center;
    /* margin-bottom: 30px; */
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    bottom: 30px;

[class*=" el-icon-"], [class^=el-icon-] {
    color: #9BA3A9;
}
.el-pagination.is-background .btn-next, .el-pagination.is-background .btn-prev, .el-pagination.is-background .el-pager li {
    margin: 0 12px;
    background-color: #E7EBEE;
    color: #9BA3A9;
    // min-width: 30px;
    border-radius: 5px;
}
.el-pagination button, .el-pagination span:not([class*=suffix]) {
    display: inline-block;
    font-size: 13px;
    width: 48px;
    height: 48px;
    line-height: 48px;
    vertical-align: top;
    box-sizing: border-box;
}
.el-pager li {
    padding: 0 4px;
    font-size: 18px;
    width: 48px;
    height: 48px;
    line-height: 48px;
    box-sizing: border-box;
    text-align: center;
    border-radius: 5px;
    background: red;
    font-weight:normal ;
}
.el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #296ABC;
    color: #FFF;
}
.el-pager .more::before{
  line-height: 48px;
}
}
</style>
<style lang='scss'>
.nwsPageW{
[class*=" el-icon-"], [class^=el-icon-] {
   
    color: #868686;
}
}
</style>
<style lang='scss' scoped>
@import "@/assets/css/mixin";
.autoH{
    height: auto !important;
}
.nwsPageW{
    background:$background-color;
    overflow: hidden;
    position: relative;
    .pageTop{
         width: $page-width;
         margin:33px auto;
         display: flex;
         justify-content: space-between;
         align-items: center;
         &_img{
             width: 402px;
             height: 74p;
         }
         &_search{
             width: 521px;
             height: 56px;
             box-shadow: 0px 3px 13px rgba(0, 0, 0, 0.1);
             border-radius: 10px;
             background: white;
             box-sizing: border-box;
              display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;
            &_close{
                cursor: pointer;
                position: absolute;
                left: 438px;
                top: 18px;
            }
            &_input{
                width: 410px;
                height: 56px;
                border: none;
                margin-left: 20px;
                background: none;
                outline:none;
                color: #636363;
                
            }
                input::-webkit-input-placeholder {
                    /* placeholder颜色  */
                        color: rgba(51, 51, 51,0.25);
                    /* placeholder字体大小  */
                        font-size: 18px;
                    /* placeholder位置  */    
                    }
            
                &_icon{
                    cursor: pointer;
                    width: 56px;
                    height: 56px;
                    background: #296ABC;
                    border-radius: 0 10px 10px 0;
                    position: relative;
                    
                    img{
                        width: 24px;
                        height: 24px;
                        position: absolute;
                        margin: auto;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                    }

                
                }
             
         }
    }

    .pageBody{
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin:0 auto;
        width: $page-width;
        margin-bottom: 62px;
        &_left{
             width: 195px;
             background: white;
             box-sizing: border-box;
             &_btn{
                 font-size: 20px;
                 color: #333333;
                 height: 66px;
                 line-height: 66px;
                 border-left:6px solid #ffffff;
                 padding-left: 19px;
                 cursor: pointer;
                  &.active{
                     border-left:6px solid #316FA4;
                  }

             }
            

             
        }
        &_right{
            position: relative;
             width: 985px;
             height: 806px;
             background: white;
             box-sizing: border-box;
             font-size: 20px;
             color:#8D8D8D;
             padding: 19px  30px;
             &_title{
                 border-bottom: 2px solid #E6ECEF;
                 padding-bottom: 20px;
                 display: flex;
                 justify-content: flex-start;
                 span:nth-child(1){
                     cursor: pointer;
                }
                 span:nth-child(3){
                     cursor: pointer;
                     &.active{
                         color: #316FA4;
                     }
                 }
                 
                 span:nth-child(5){
                     color: #316FA4;
                     @include clamp(1);
                     @include no-wrap();
                     width: 70%;
                    //  line-height: 30px;
                 }
                &_icon{
                    margin: 0 16px;
                }
                &_txt{

                }
                 
             }

             .list{
                 .otherItem{
                     text-align: center;
                     padding: 110px 0;
                 }
                 .item{
                    border-bottom: 1px dashed #D5D6D6;
                    padding: 20px 0;
                    

                     &_sub{
                         display: flex;
                         justify-content: space-between;
                         align-items: center;
                         margin-bottom: 10px;
                         cursor: pointer;
                        &_title{
                            font-size: 20px;
                            color:#333333;
                        }
                        &_time{
                            font-size: 18px;
                            color:#999999;
                        }
                     }
                     &_content{
                         cursor: pointer;
                        font-size: 18px;
                        width: 100%;
                        color:#999999;
                        @include clamp(1);
                        @include no-wrap();
                     }

                 }
             }

             .detailsNews{
                    border-bottom: 1px solid #E6ECEF;
                    padding: 20px 0;
                    

                     &_sub{
                         display: flex;
                         justify-content: space-between;
                         align-items: center;
                         margin-bottom: 10px;
                        &_title{
                            font-size: 20px;
                            color:#333333;
                            font-weight: bold;
                        }
                        &_time{
                            font-size: 16px;
                            color:#296ABC;
                        }
                     }
                     &_content{
                        font-size: 18px;
                        width: 100%;
                        color:#333333;
                        line-height: 36px;
                        margin-top: 40px;
                        
                     }
             }


            
        }
    }
}
</style>