<!--  -->
<template>
  <div class="cardQuqryW" v-if="isVisible">
       <div  v-show="type<2" class="cardQuqryW_title">{{title}}</div>
       <div  v-show="type<2" class="cardQuqryW_cont">
         <div class="cardQuqryW_cont_tag" v-for="(item,index) in tagList" :key="index"  :ref="`btn${index+1}`" @click="changePage(index+1,item,true)">{{item.name}}<i class="el-icon-success icon"></i></div>
         <div v-show="type==1" class="inputW">
              <input class="inputW_input"  placeholder='最低值' type="number" autocomplete="off" v-model="rentMin">
              <span>-</span>
              <input class="inputW_input"  placeholder='最高值' type="number" autocomplete="off" v-model="rentMax">
         </div>
       </div>

       <div class="cardQuqryWOther" v-show="type==2">
          <div class="cardQuqryWOther_title">{{title}}</div>
          <span class="cardQuqryWOther_tag">≥</span>
          <input class="cardQuqryWOther_input"  placeholder='输入面积(㎡)' v-model="rentArea" type="number" autocomplete="off" >
          <!-- <div class="cardQuqryWOther_title1">连续空置层数:</div>
           <el-select class="cardQuqryWOther_input1"  v-model="value" placeholder="请选择">
                <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
                </el-option>
            </el-select> -->

       </div>
       <div class="cardQuqryWOther" v-show="type==3">
          <div class="cardQuqryWOther_title">{{title}}</div>
          <span class="cardQuqryWOther_tag">≥</span>
          <input class="cardQuqryWOther_input"  placeholder='输入空置率' v-model="vacancyRate" type="number" autocomplete="off" >
          <!-- <div class="cardQuqryWOther_title1">连续空置层数:</div>
           <el-select class="cardQuqryWOther_input1"  v-model="value" placeholder="请选择">
                <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
                </el-option>
            </el-select> -->

       </div>
       
  </div>
</template>

<script>
import mode from '@/api/modeType'

export default {
name: '',
  data () {
    return {
        rentMin:'',
        rentMax:'',
        rentArea:null,
        vacancyRate:null,
        preId:0,
         options: [
        {
          value: '选项1',
          label: '1层'
        }, {
          value: '选项2',
          label: '2层'
        }, {
          value: '选项3',
          label: '3层'
        }, {
          value: '选项4',
          label: '4层'
        }, {
          value: '选项5',
          label: '5层'
        },
        {
          value: '选项6',
          label: '6层'
        }, {
          value: '选项7',
          label: '7层'
        }, {
          value: '选项8',
          label: '8层'
        }, {
          value: '选项9',
          label: '9层'
        }, {
          value: '选项10',
          label: '10层'
        }
        ],
        value: '1层'
    };
  },

  components: {
      
  },

  props: {
       tagList:{
            type: Array,
            default:()=>[],
            required: true
      },
      isVisible:{
        type: Boolean,
        default: false,
        required: true
      },
       title:{
        type: String,
        default: '',
        required: true
      },
      apiName:{
        type: String,
        default: '',
        required: true
      },
   
       type:{
        type: Number,
        default: -1,
        required: true
      },
   
  },

  computed: {},

  watch: {
    vacancyRate(newValue,oldValue){
        
          if(this.vacancyRate>100){
              this.vacancyRate=''
              return
          }
           
      //  console.log(newValue,'newValue11')
      //  console.log(oldValue,'oldValue11')
      //   if(newValue){
            // console.log(newValue,'newValue')

            mode.vacancyRate=this.vacancyRate
            if(newValue=='')
            this.$emit('str',this.apiName,-1,mode.jisBoo)
            else
            this.$emit('str',this.apiName,newValue,mode.jisBoo)
        // }
     },
     rentArea(newValue,oldValue){
       
      //  console.log(newValue,'newValue11')
      //  console.log(oldValue,'oldValue11')
      //   if(newValue){
            console.log(newValue,'rentAreanewValue')
            mode.rentArea=this.rentArea
           
            if(newValue=='')
            this.$emit('str',this.apiName,-1,mode.jisBoo)
            else
            this.$emit('str',this.apiName,newValue,mode.jisBoo)
        // }
     },

     rentMin(newValue,oldValue){
       
        // if(newValue){
            console.log(newValue,'rentMinnewValue')
            console.log(oldValue,'rentMinnoldValue')
             if(this.rentMin!='' && this.rentMax!='' && parseInt(this.rentMin)<parseInt(this.rentMax)){
                 
                  if(this.preId!=0){
                    
                    this.$refs[`btn${this.preId}`][0].classList.remove('active')
                    this.$refs[`btn${this.preId}`][0].children[0].classList.remove('active')
                    this.preId=0
                 }
                 this.$emit('str',this.apiName,[this.rentMin,this.rentMax],mode.jisBoo)  
                 mode.rentMax=this.rentMax
                 mode.rentMin=this.rentMin
                 mode.rentNum=0
            }else{
                 
                 if(this.preId==0){
                    mode.rentMax=''
                    mode.rentMin=''
                   this.$emit('str',this.apiName,-1,mode.jisBoo)
                 }  
                 
            }
        // }
     },

     rentMax(newValue,oldValue){
       
        // if(newValue){
            // console.log(this.rentMin,'this.rentMin')
            // console.log(this.rentMax,'this.rentMax')
            // console.log(parseInt(this.rentMin)<parseInt(this.rentMax),'newValue')
            if(this.rentMin!='' && this.rentMax!='' && parseInt(this.rentMin)<parseInt(this.rentMax)){
                  if(this.preId!=0){
                    this.$refs[`btn${this.preId}`][0].classList.remove('active')
                    this.$refs[`btn${this.preId}`][0].children[0].classList.remove('active')
                    this.preId=0
                 }
                  mode.rentMax=this.rentMax
                  mode.rentMin=this.rentMin
                  mode.rentNum=0
                 this.$emit('str',this.apiName,[this.rentMin,this.rentMax],mode.jisBoo)  
            }else{
                if(this.preId==0){
                    mode.rentMax=''
                    mode.rentMin=''
                   this.$emit('str',this.apiName,-1,mode.jisBoo)
                 }  

            }
              
        // }
     }


  },

  methods: {
   
       changePage(_id,_item,_boo){
               console.log(_id,'_id111')
               console.log(_item,'_item_item_item')
         

         if(_id==this.preId){
            this.$refs[`btn${this.preId}`][0].classList.remove('active')
            this.$refs[`btn${this.preId}`][0].children[0].classList.remove('active')
            this.preId=0

              if(this.title=='楼宇类型:')
                 mode.projectCategory=0
              if(this.title=='所属街道:')
                  mode.townId=0
              if(this.title=='装修标准:')
                  mode.decorateStandard=0          
              if(this.title=='运营模式:')
                  mode.operatingMethod=0   
               if(this.title=='楼宇特色:')
                 mode.identifiedLevel=0    
              if(this.title=='建成年份:')
                 mode.setupYear=0 

               if(this.title=='最高租金:')  
                  mode.rentNum=0

           if(_boo){
            mode.newCurrentPage=1
          }
          console.log(mode.newCurrentPage,'mode.newCurrentPage2222')

        //  if(_boo){
          if(_item=='自贸区楼宇')
            this.$emit('str','ifFreeTrade',-1,_boo)
            else
            this.$emit('str',this.apiName,-1,_boo)
            return
           }
        //  }
           
        if(_boo){
            mode.newCurrentPage=1
          }
          console.log(mode.newCurrentPage,'mode.newCurrentPage2222222222')

         this.$refs[`btn${_id}`][0].classList.add('active')
        //  console.log(this.$refs[`btn${_id}`][0])
        //  console.log(this.$refs[`btn${_id}`][0].children[0])
        this.$refs[`btn${_id}`][0].children[0].classList.add('active')
        
     if(this.title=='楼宇类型:')
        mode.projectCategory=_id
     if(this.title=='所属街道:')
        mode.townId=_id
     if(this.title=='装修标准:')
        mode.decorateStandard=_id          
     if(this.title=='运营模式:')
        mode.operatingMethod=_id  
        
     if(this.title=='楼宇特色:')
         mode.identifiedLevel=_id 
     
      if(this.title=='建成年份:')
         mode.setupYear=_id 

       if(this.title=='最高租金:')  
         mode.rentNum=_id

         
         if(this.preId!=0){
           this.$refs[`btn${this.preId}`][0].classList.remove('active')
           this.$refs[`btn${this.preId}`][0].children[0].classList.remove('active')
         }

         if(this.type==1){
              this.rentMin=null
              this.rentMax=null 
         }
        // console.log(this.tagList[_id-1],'this.tagList[_id-1]')
        //  if(_item=='自贸区楼宇')
        //  this.$emit('str',this.apiName1,this.tagList[_id-1])
        //  else

        // if(_boo)
         this.$emit('str',this.apiName,this.tagList[_id-1].value,_boo)

             
         this.preId=_id

      }
  },

  created(){},//生命周期 - 创建完成（可以访问当前this实例）
  mounted(){
    mode.jisBoo=false
    // this.preId=
    console.log(this.title,'title')
    setTimeout(()=>{
        console.log(this.tagList,'tagList')
        if(this.title=='楼宇类型:' && mode.projectCategory!=0)
        this.changePage(mode.projectCategory,this.tagList[mode.projectCategory],false)

         if(this.title=='建成年份:' && mode.setupYear!=0)
          this.changePage(mode.setupYear,this.tagList[mode.setupYear],false)

        if(this.title=='所属街道:' && mode.townId!=0)
        this.changePage(mode.townId,this.tagList[mode.townId],false)
         if(this.title=='楼宇特色:' && mode.identifiedLevel!=0)
          this.changePage(mode.identifiedLevel,this.tagList[mode.identifiedLevel],false)

        if(this.title=='装修标准:' && mode.decorateStandard!=0)
        this.changePage(mode.decorateStandard,this.tagList[mode.decorateStandard],false)
        if(this.title=='运营模式:' && mode.operatingMethod!=0)
        this.changePage(mode.operatingMethod,this.tagList[mode.operatingMethod],false)

         if(this.title=='最高租金:'){
           if(mode.rentNum!=0){
              this.changePage(mode.rentNum,this.tagList[mode.rentNum],false)
           }else{
              this.rentMax=mode.rentMax
              this.rentMin=mode.rentMin

           }
             
         }
        

        if(this.title=='空置率:' && mode.vacancyRate!=0)
          this.vacancyRate= mode.vacancyRate

        if(this.title=='单层连续可租面积㎡:' && mode.rentArea!=0)
          this.rentArea= mode.rentArea

        

    },300)
    
    
    

  },//生命周期 - 挂载完成（可以访问DOM元素
  beforeCreate(){ },//生命周期 - 创建之前
  beforeMount(){ } ,//生命周期 - 挂载之前
  beforeUpdate(){ },//生命周期 - 更新之前
  updated(){ },//生命周期 - 更新之后
  beforeDestroy(){ },//生命周期 - 销毁之前
  destroyed(){ },//生命周期 - 销毁完成
  activated(){ }//如果页面有keep-alive缓存功能，这个函数会触发
}

</script>
<style lang='scss'>
.cardQuqryW_cont{
[class*=" el-icon-"], [class^=el-icon-] {
   
    color: #296ABC;
}
}
</style>
<style lang='scss' scoped>
.el-input__inner {
    border-radius: 7px;
    font-size: 16px !important;

}
.cardQuqryWOther{
    font-size: 20px;
    color: #333333;
    display: flex;
    align-items: center;
    // margin:10px 0;
    justify-content: flex-start;
        &_title{
        font-weight: bold;
        margin-right: 20px;
        // margin-top: 16px;
        }
        &_title1{
        font-weight: bold;
        margin-left: 68px;
        // margin-top: 16px;
        }
        &_tag{
            font-size: 33px;
            margin:0  8px 0 20px;
            color: #7E7E7E;
        // margin-top: 16px;
        }
         &_input1{
                        
          
            font-size: 18px;
          
            width: 152px;
            height: 38px;
            margin:10px 10px 10px 14px;
            font-size: 16px;
            color: #272727;
            border-radius: 7px;

                }
            input::-webkit-input-placeholder {
            /* placeholder颜色  */
                color: #999999;
            /* placeholder字体大小  */
                font-size: 16px;
            /* placeholder位置  */
            
            }        
        &_input{
                        
            text-align: center;
            font-size: 18px;
            padding: 7px 0;
            border: 1px solid #E2E2E2;
            width: 152px;
            height: 38px;
            border-radius: 7px;
            margin:10px;
            box-sizing: border-box;
            font-size: 16px;
            color: #606266;
            outline:none;

                }
            input::-webkit-input-placeholder {
            /* placeholder颜色  */
                color: #999999;
            /* placeholder字体大小  */
                font-size: 16px;
            /* placeholder位置  */
            
            }        
    }
.cardQuqryW{
    font-size: 20px;
    color: #333333;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    
    &_title{
      font-weight: bold;
      margin-right: 20px;
      margin-top: 16px;
      
      
    }
     &_cont{
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-wrap: wrap;
            width: fit-content;
            .inputW{
                &_input{
                        
                    text-align: center;
                    font-size: 18px;
                    padding: 7px 0;
                    border: 1px solid #E2E2E2;
                    width: 152px;
                    height: 38px;
                    border-radius: 7px;
                    margin:10px;
                    box-sizing: border-box;
                    font-size: 16px;
                    color: #606266;
                    outline:none;

                        }
                   input::-webkit-input-placeholder {
                    /* placeholder颜色  */
                        color: #999999;
                    /* placeholder字体大小  */
                        font-size: 16px;
                    /* placeholder位置  */
                    
                    }        
            }


        &_tag{
        cursor: pointer;
        text-align: center;
        font-size: 18px;
        padding: 7px 0;
        border: 1px solid #E2E2E2;
        width: 152px;
        height: 38px;
        border-radius: 7px;
        margin:10px;
        position: relative;
        box-sizing: border-box;
         &.active{
           color: #296ABC;
           border: 1px solid #296ABC;
           background: rgba(0, 113, 255,.12);
                }
            .icon{
                position: absolute;
                right: 3px;
                top: 9px;
                display: none;
                &.active{
                    display: block;
                }
            }
            

        }
       
        &_tag:hover{
           color: #296ABC;
           border: 1px solid #296ABC;
           background: rgba(0, 113, 255,.12);
         }
    }
}
</style>
